import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateSalvageRequestResp, SalvageRequest } from '@xpo-ltl-2.0/sdk-shipment';

@Component({
  selector: 'app-salvage-form-success-dialog',
  templateUrl: './salvage-form-success-dialog.component.html',
  styleUrls: ['./salvage-form-success-dialog.component.scss'],
})
export class SalvageFormSuccessDialogComponent implements OnInit {
  requestId: number;
  proNumber: string;

  constructor(private dialogRef: MatDialogRef<SalvageFormSuccessDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: SalvageRequest) {
    this.requestId = this.data?.salvageRequestId;
    this.proNumber = this.data?.proNbr;
  }

  closeDialog() {
    this.dialogRef.close(true);
  }

  ngOnInit(): void {}
}
