import { Component, NgZone, OnDestroy, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { SimpleSnackBar, MatSnackBarRef, MatSnackBar } from '@angular/material/snack-bar';
import { NotificationMessage, NotificationService, NotificationTypeEnum } from '@xpo-ltl/data-api';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit, OnDestroy {
  message: string;
  show: boolean;

  @Input() showProgressSpinner = true;

  private unsubscribe = new Subject<void>();
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  constructor(private notificationService: NotificationService, private zone: NgZone, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.notificationService
      .getSubscriber()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((message: NotificationMessage) => {
        try {
          this.zone.run(() => {
            if (message.type !== NotificationTypeEnum.ShowSnackbar) {
              this.message = message.text;
              this.show = message.type === NotificationTypeEnum.ShowOverlay;
            } else {
              this.snackBarRef = this.snackBar.open(
                message.text,
                message.snackbarConfig != null && message.snackbarConfig.actionHandler != null && message.snackbarConfig.actionHandler.actionLabel() != null
                  ? message.snackbarConfig.actionHandler.actionLabel()
                  : null,
                {
                  duration: message.snackbarConfig.durationInMillis,
                  panelClass: [
                    message.snackbarConfig.status.toLowerCase() === 'error'
                      ? 'notification__snackbar-error'
                      : message.snackbarConfig.status.toLowerCase() === 'warning'
                      ? 'notification__snackbar-warning'
                      : 'notification__snackbar-success',
                  ],
                }
              );
              if (message.snackbarConfig != null && message.snackbarConfig.actionHandler != null) {
                if (message.snackbarConfig.actionHandler.onAction != null) {
                  this.snackBarRef
                    .onAction()
                    .pipe(take(1))
                    .subscribe(message.snackbarConfig.actionHandler.onAction);
                }
                if (message.snackbarConfig.actionHandler.afterOpened != null) {
                  this.snackBarRef
                    .afterOpened()
                    .pipe(take(1))
                    .subscribe(message.snackbarConfig.actionHandler.afterOpened);
                }
                if (message.snackbarConfig.actionHandler.afterDismissed != null) {
                  this.snackBarRef
                    .afterDismissed()
                    .pipe(take(1))
                    .subscribe(message.snackbarConfig.actionHandler.afterDismissed);
                }
              }
            }
          });
        } catch (error) {
          // todo: log
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
