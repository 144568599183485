import { Component, OnInit } from '@angular/core';
import { InjectedDialogComponent } from '../../interfaces/injected-dialog-component.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-alert',
  template: `
    <div>Your content here!</div>
  `
})
export class AlertComponent implements InjectedDialogComponent {
  public dataObservable: Observable<any>;

  constructor() {}
}
