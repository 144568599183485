import { Pipe, PipeTransform } from '@angular/core';
import * as ProNbrFormatterUtils from '../utils/pro-number-formatter.utils';
import * as _ from 'lodash';

@Pipe({
  name: 'dynamicProNumberFormatter',
})
export class DynamicProNumberFormatterPipe implements PipeTransform {
  transform(value: string, args?: string): string {
    let formatDigits = 10;
    if (args) {
      formatDigits = Number.parseInt(args);
    }

    const formattedNbr = this.conditionProNumberNew(value, formatDigits);
    if (ProNbrFormatterUtils.isValidChildPro(formattedNbr)) {
      return ProNbrFormatterUtils.formatTenDigitProNbr(formattedNbr);
    }
    return formattedNbr;
  }

  conditionProNumberNew(proNumber = '', digits = 9) {
    if (proNumber === '' || proNumber === null) {
      return '';
    }
    proNumber = ProNbrFormatterUtils.replaceNonDigitChars(proNumber);
    proNumber = this.convert(proNumber, digits);
    return proNumber;
  }

  conditionProNumber(proNumber = '', digits = 9) {
    if (proNumber === '' || proNumber === null) {
      return '';
    }

    if (ProNbrFormatterUtils.isValidChildPro(proNumber)) {
      return ProNbrFormatterUtils.formatTenDigitProNbr(proNumber);
    }

    proNumber = ProNbrFormatterUtils.replaceNonDigitChars(proNumber);
    proNumber = this.convert(proNumber, digits);
    return proNumber;
  }

  convert(proNumber = '', digits = 9) {
    let part1: string, part2: string, trim: any, trimmed: string;
    trim = _.partialRight(ProNbrFormatterUtils.trimChars.bind(this), '0', 'g', digits);
    trimmed = trim(proNumber);

    if (trimmed.length < 9) {
      // if it had a leading 0 as part of the real number
      if (trimmed.length < 8) {
        trimmed = `00${trimmed}`;
      } else {
        trimmed = `0${trimmed}`;
      }
    }
    part1 = trimmed.slice(0, 3);
    part2 = trimmed.slice(3);
    part2 = part2.length > 6 ? trim(part2) : part2;

    let retValue = '';
    if (digits === 11) {
      retValue = `0${part1}0${part2}`;
    } else if (digits === 10) {
      retValue = `${part1}-${part2}`;
    } else {
      retValue = part1.concat(part2);
    }

    return retValue;
  }
}
