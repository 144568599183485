export enum ImageSearchFormNames {
  SearchKey = 'searchKey',
  SelectedDateRange = 'selectedDateRange',
  ReferenceNbr = 'referenceNbr',
  SelectedTags = 'selectedTags',
  InstId = 'instId',
  ThumbnailImage = 'thumbnailImage',
  ProNbr = 'proNbr',
  ReportingSicCd = 'reportingSicCd',
  OverPairedWithShortInd = 'overPairedWithShortInd',
  Brand = 'brand',
  Model = 'model',
  Description = 'description',
  Color = 'color',
  LengthNbr = 'lengthNbr',
  WidthNbr = 'widthNbr',
  HeightNbr = 'heightNbr',
  SerialNbr = 'serialNbr',
  UpcCd = 'upcCd',
  DmsUrl = 'dmsUrl',
  AuditInfo = 'auditInfo',
  PackageCd = 'packageCd',
  PiecesCount = 'piecesCount',
  WeightLbs = 'weightLbs',
  Comment = 'comment',
  OriginProNbr = 'originProNbr',
  UploadDate = 'uploadDate',
  PartialPairedWithShortInd = 'partialPairedWithShortInd',
  FromDate = 'fromDate',
  ToDate = 'toDate',
}
