import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { LoggingApiService, SdkLoggingModule } from '@xpo-ltl/sdk-logging';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ShipmentApiService } from '@xpo-ltl-2.0/sdk-shipment';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { DocumentManagementApiService } from '@xpo-ltl/sdk-documentmanagement';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
// import { NavigatorService, XpoNavigatorModule } from '@xpo-ltl/navigator';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';

import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { ConfirmCancelComponent } from './dialogs/confirm-cancel/confirm-cancel.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { AppConstantsService } from './services/app-constants.service';
import { NotificationComponent } from './components/notification/notification.component';
import { AppDialogsModule } from './app-dialogs.module';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { ImageLabelDialogComponent } from './components/image-label-dialog/image-label-dialog.component';
import { ImageUploadTransformer } from './components/image-upload/image-upload-transformer/image-upload-transformer';
import { CommodityPackageCdPipe } from './pipes/commodity-package-cd.pipe';
import { ProNumberFormatterPipe } from './pipes/pro-number-formatter.pipe';
import { DmsApiService } from './services/dms-api/dms-api.service';
import { ImageUploadService } from './services/image-upload-service/image-upload.service';
import { SalvageRequestService } from './services/salvage-request-service/salvage-request.service';
import { ImageSearchComponent } from './components/image-search/image-search.component';
import { ImageDetailsComponent } from './components/image-details/image-details.component';
import { CoreModule } from './core/core.module';
import { InfrastructureApiService } from '@xpo-ltl/sdk-infrastructure';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XPO_AUTH_CONFIG, XpoAuthConfig } from '@xpo/ngx-auth';
import { XpoBadgeModule, XpoCheckboxModule, XpoEmptyStateModule, XpoPageNotFoundRoutingModule, XpoTrayModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { SalvageRequestComponent } from './components/image-search/salvage-request/salvage-request.component';
import { SalvageFormSuccessDialogComponent } from './dialogs/salvage-form-success-dialog/salvage-form-success-dialog.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { XpoBoardModule } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ltl-grid';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FeedbackImageComponent } from './dialogs/feedback-image/feedback-image/feedback-image.component';
import { ImageLandingComponent } from './components/image-landing/image-landing.component';
import { EditSalvageRequestComponent } from './components/edit-salvage-request/edit-salvage-request.component';
import { EnforceAutocompleteSelectionDirective } from './validators/enforce-autocomplete-selection.directive';
import { DecimalNumberDirective } from './validators/decimal-number.directive';
import { DynamicProNumberFormatterPipe } from './pipes/dynamic-pro-number-formatter.pipe';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    SafeHtmlPipe,
    AppHeaderComponent,
    AppFooterComponent,
    ConfirmCancelComponent,
    NotAuthorizedComponent,
    ImageSearchComponent,
    ImageUploadComponent,
    ImageDetailsComponent,
    SalvageRequestComponent,
    ImageLabelDialogComponent,
    NotificationComponent,
    CommodityPackageCdPipe,
    ProNumberFormatterPipe,
    DynamicProNumberFormatterPipe,
    SalvageFormSuccessDialogComponent,
    ImageLandingComponent,
    FeedbackImageComponent,
    EditSalvageRequestComponent,
    EnforceAutocompleteSelectionDirective,
    DecimalNumberDirective
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    ConfigManagerModule,
    NgxSpinnerModule,
    MatTooltipModule,
    SdkLoggingModule,
    XpoButtonModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatDialogModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    XpoCheckboxModule,
    XpoBadgeModule,
    // XpoNavigatorModule,
    XpoBoardModule,
    XpoAgGridBoardModule,
    XpoAgGridModule,
    XpoEmptyStateModule,
    AppDialogsModule,
    CommonModule,
    NgxGalleryModule,
    NgxDaterangepickerMd,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: false }),
    CoreModule,
    MatSidenavModule,
    XpoTrayModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    AppRoutingModule,
    XpoPageNotFoundRoutingModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    AppConstantsService,
    ImageUploadTransformer,
    DataApiService,
    NotificationService,
    ShipmentApiService,
    DmsApiService,
    DocumentManagementApiService,
    LocationApiService,
    LoggingApiService,
    ImageUploadService,
    SalvageRequestService,
    InfrastructureApiService,
    NgxImageCompressService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
  entryComponents: [ImageLabelDialogComponent, ImageDetailsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(/*private navigatorService: NavigatorService*/) {
    // navigatorService.init();
  }
}
