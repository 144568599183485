import { FormArray, FormControl, FormGroup, AbstractControl } from '@angular/forms';

/**
 * Outputs to the console the key, value, and error
 * @param control
 */
export function touchAllControls(control: AbstractControl) {
  // Uncommnet to debug if a control is pending
  // if (control.pending) {
  //   console.log(control);
  // }
  if (control instanceof FormControl) {
    if (control) {
      // Uncomment to debug what control is invalid
      // Object.keys(control.parent.controls).forEach((key) => {
      //   if (control.parent.controls[key] === control) {
      //     console.log('Key:', key, 'Value:', control.value, 'Error:', control.errors);
      //   }
      // });

      control.markAsTouched();
      control.updateValueAndValidity({ emitEvent: false });
    }
  } else if (control instanceof FormArray) {
    Object.keys((control as FormArray).controls).forEach(key => {
      touchAllControls(control.controls[key]);
    });
  } else if (control instanceof FormGroup) {
    Object.keys((control as FormGroup).controls).forEach(key => {
      touchAllControls(control.controls[key]);
    });
  }
}
