import { Injectable } from '@angular/core';
import { User } from '@xpo-ltl/sdk-common';

@Injectable()
export class AppConstantsService {
  public user: User;

  constructor() {}

  public isAthorizedUser(user: User) {
    // TODO: check the user's roles for a specific group!
    return true;
  }
}
