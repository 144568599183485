import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ApiRequest, DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { DmsAccessToken } from './model/dms-access-token';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { decode } from 'typescript-base64-arraybuffer';
import * as _ from 'lodash';
import { ConditioningService } from '@xpo-ltl/common-services';
import { DmsDocumentRqst } from './model/dms-document-rqst';
import { switchMap, take, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { ConfigManagerProperties } from '../../classes/config-manager-properties.enum';

@Injectable()
export class DmsApiService {
  private dmsApiEndpoint: string;
  private apiUrl: string;
  private dmsAccessToken: DmsAccessToken;

  constructor(
    private http: HttpClient,
    private dataManager: DataApiService,
    private config: ConfigManagerService,
    private conditioningService: ConditioningService,
    private notificationService: NotificationService
  ) {
    this.dmsApiEndpoint = config.getSetting(ConfigManagerProperties.dmsApiEndpoint);
    this.apiUrl = config.getSetting(ConfigManagerProperties.apiUrl);
  }
  private generateFullyQualifiedUrl(url: string = '') {
    const conditionedApiRoot = `${this.apiUrl}${this.apiUrl.endsWith('/') ? '' : '/'}`;
    const conditionedServiceUri = `${url.startsWith('/') ? url.substring(1) : url}`;
    return `${conditionedApiRoot}${conditionedServiceUri}`;
  }

  private authenticateDms(): Observable<DmsAccessToken> {
    const requestUri = `${this.dmsApiEndpoint}/oauth/dmstoken`;
    const request = new ApiRequest(requestUri);
    if (this.dmsAccessToken && !this.isAuthTokenExpired()) {
      return of(this.dmsAccessToken);
    }

    return this.dataManager.post(request).pipe(
      tap(response => {
        this.dmsAccessToken = response as DmsAccessToken;
        this.dmsAccessToken.expiresAt = new Date(Date.now() + this.dmsAccessToken.expires_in * 1000);
      })
    );
  }
  private isAuthTokenExpired() {
    return new Date() > this.dmsAccessToken.expiresAt;
  }

  private getDmsDocument2(request: DmsDocumentRqst): Observable<ArrayBuffer> {
    return this.authenticateDms().pipe(
      take(1),
      switchMap(token => {
        const requestUri = this.generateFullyQualifiedUrl(`${this.dmsApiEndpoint}/document/${request.corpCode}/${request.docClass}/${request.archiveTimestamp}`);
        return this.http.get(requestUri, {
          responseType: 'arraybuffer',
          headers: { DMSAuth: token.access_token },
        });
      })
    );
  }

  public getDmsDocument(documentRequest: { referenceNumber: string }): Observable<Uint8Array> {
    const mySubject = new Subject<Uint8Array>();

    // if (documentRequest.docType === DocType.InvoicePdf) {

    const request = {
      corpCode: 'DEMO',
      docClass: 'OSDF',
      archiveTimestamp: documentRequest.referenceNumber,
    };

    this.getDmsDocument2(request)
      .pipe(take(1))
      .subscribe(
        success => {
          mySubject.next(new Uint8Array(success));
        },
        error => {
          if (_.result(error, 'error.errorCode', undefined)) {
            mySubject.error(`${_.result(error, 'error.message', '')}`);
          } else {
            mySubject.error('No data found');
          }
        }
      );

    /* } else {

      const httpHeaders: HttpHeaders = new HttpHeaders();
      httpHeaders.append('Content-Type', 'multipart/form-data');
      const apiRequest = new ApiRequest(`/imaging/1.0/shipments/${this.conditioningService.conditionProNumber(documentRequest.referenceNumber, 11)}/imaged-docs?imageFormat=pdf&imageType=${documentRequest.docType}&multiPartResp=false`);
      apiRequest.httpOptions = {headers: httpHeaders};
      apiRequest.dataOptions = ApiRequest.concealedCall;


      this.dataManager.get(apiRequest).subscribe(
        () => { //FOR NOW WE WILL GET AN ERROR BECAUSE THE SERVICE ALWAYS RETURNS MULTI PARTS
        },
        error => {
          if (_.result(error, 'error.errorCode', undefined)) {
            mySubject.error(`${_.result(error, 'error.message', '')}`);
          } else {
            let value: string = error.text;
            if (!value) {
              this.notificationService.showSnackBarMessage(error);
            } else {
              value = value.substring(value.indexOf('{'), value.lastIndexOf('}') + 1);
              const base64Data = JSON.parse(value);
              const arrayData = _.result(base64Data, 'data.imagedDocument[0].imageFiles[0].base64Data', undefined);
              if (arrayData) {
                mySubject.next(decode(arrayData));
              } else {
                mySubject.error('No data found');
              }
              mySubject.complete();
            }
          }
        });
    } */
    return mySubject.asObservable();
  }

  public postDMSDocument(request: DmsDocumentRqst) {
    return this.authenticateDms().pipe(
      take(1),
      switchMap(token => {
        const requestUri = this.generateFullyQualifiedUrl(`${this.dmsApiEndpoint}/document/${request.corpCode}/${request.docClass}`);
        var fd = new FormData();
        const index = [{ tag: 'PRO', value: '06420028181' }];
        let inputFile = { 'Content-Type': 'application/octet-stream', body: request.document, 'Content-Disposition': 'form-data', filename: '1' };
        let archiveRequest = {
          'Content-Type': 'application/json',
          body: {
            docNumber: '06420028181',
          },
          'Content-Disposition': 'form-data',
        };
        fd.append('inputFile', JSON.stringify(inputFile));
        fd.append('archiveRequest', JSON.stringify(archiveRequest));

        const httpHeaders: HttpHeaders = new HttpHeaders();
        httpHeaders.append('Content-Type', ''); //application/octet-stream
        httpHeaders.append('dmsAuth', token.access_token); //application/octet-stream
        //   httpHeaders.append('Content-Disposition','form-data');
        // httpHeaders.append('name','archiveRequest');

        return this.http.post(requestUri, fd, { headers: { dmsAuth: token.access_token } });

        /* return this.http.post(requestUri, {
            document: request.document,
            format: 'JSON'
          }, {headers: {dmsAuth: token.access_token}});
          */

        //  }, {headers: {dmsAuth: token.access_token, corpCode: 'DEMO' , docClass : 'OSDF'}});
      })
    );
  }
}
