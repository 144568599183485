<app-notification></app-notification>
<div class="app-container">
  <div class="app-container-stretch-box">
    <app-header></app-header>

    <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex="" class="app-container-content" [ngClass]="{ 'app-container-content-non-prod': !isProduction }">
      <div class="app-container">
        <nav mat-tab-nav-bar class="tagGroup">
          <a mat-tab-link *ngFor="let tabItem of tabs" [routerLink]="tabItem.route" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
            <span id="navstepstext">{{ tabItem.label }}</span>
          </a>
        </nav>
        <router-outlet></router-outlet>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</div>
