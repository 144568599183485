import { Component, OnInit, Inject } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmCancelDataInterface } from '../../interfaces/confirm-cancel-data';

@Component({
  selector: 'app-confirm-cancel',
  templateUrl: './confirm-cancel.component.html',
  styleUrls: ['./confirm-cancel.component.scss']
})
export class ConfirmCancelComponent implements OnInit {

  title: string;
  content: SafeHtml;
  leftButtonTitle: string;
  rightButtonTitle: string;

  constructor(private dialogRef: MatDialogRef<ConfirmCancelComponent>,
              @Inject(MAT_DIALOG_DATA) private data: ConfirmCancelDataInterface,
              private sanitizer: DomSanitizer) {

    this.title = data.heading;
    this.content = sanitizer.bypassSecurityTrustHtml(data.body);
    this.leftButtonTitle = data.leftButtonTitle;
    this.rightButtonTitle = data.rightButtonTitle;

  }

  ngOnInit() {
  }

}
