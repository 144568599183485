<div class="paginator-container">
  <div class="paginator-page-select" [ngClass]="{'paginator-dropdown__select-padding': this.currentPageIndex.toString().length >= 4}">
    <span>Page: </span>
    <mat-form-field class="paginator-page-dropdown">
      <mat-select #pageSelectDropdown class="paginator-page-dropdown-select" [ngStyle]="{'width': this.dropdownWidth}" [style.width.px]="this.dropdownWidth"  [value]="displayPageIndex" (selectionChange)="changePageSelect($event.value)">
        <mat-option [value]='page' *ngFor="let page of (pages$ | async)">{{ page }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="paginator-page-size">
    <div class="paginator-page-size-label">Items per page: </div>

    <mat-form-field class="paginator-page-dropdown">
      <mat-select class="paginator-page-dropdown-select" id="pageSizeDropdown" [value]="pageSize" (selectionChange)="changePageSize($event.value)">
        <mat-option *ngFor="let pageSizeOption of pageSizeOptions" [value]="pageSizeOption">
          {{pageSizeOption}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="paginator-range-actions">
    <div class="paginator-range-label">
      {{getRangeLabel()}}
    </div>

    <button mat-icon-button type="button" class="paginator-navigation-btn" id="paginatorFirstBtn" (click)="firstPage()" [disabled]="!hasPreviousPage()"
            *ngIf="showFirstLastButtons">
      <svg class="paginator-icon" viewBox="0 0 24 24" focusable="false">
        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" />
      </svg>
    </button>
    <button mat-icon-button type="button" class="paginator-navigation-btn" id="paginatorPrevBtn" (click)="previousPage()" [disabled]="!hasPreviousPage()">
      <svg class="paginator-icon" viewBox="0 0 24 24" focusable="false">
        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
      </svg>
    </button>
    <button mat-icon-button type="button" class="paginator-navigation-btn" id="paginatorNextBtn" (click)="nextPage()" [disabled]="!hasNextPage()">
      <svg class="paginator-icon" viewBox="0 0 24 24" focusable="false">
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </button>
    <button mat-icon-button type="button" class="paginator-navigation-btn" id="paginatorLastBtn" (click)="lastPage()" [disabled]="!hasNextPage()"
            *ngIf="showFirstLastButtons">
      <svg class="paginator-icon" viewBox="0 0 24 24" focusable="false">
        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z" />
      </svg>
    </button>
  </div>
</div>
