import { Component, OnInit } from '@angular/core';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { AppConstantsService } from '../../services/app-constants.service';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { XpoFeedback } from '@xpo-ltl/ngx-ltl-core';
import { FeedbackService } from '../../services/feedback.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  constructor(private config: ConfigManagerService,
              private appConstantsService: AppConstantsService,
              public feedbackService: FeedbackService,
              private dialog: MatDialog) {}

  ngOnInit() {}

  openFeedbackDialog(): void {
    this.feedbackService.feedbackConfig$.pipe(take(1)).subscribe(config => {
      this.dialog.open(XpoFeedback, { data: config });
    });
  }
}
