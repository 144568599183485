<div>
  <div class="salvageFormHeader">
    <div class="salvageFormHeading">Edit Salvage Request</div>
  </div>
  <div [formGroup]="editSalvageForm" class="salvageRequest__form">
    <div class="salvageRequest__form-container">
      <p class="salvageRequest__form-piecesCountText">
        Total Pieces : <span class="salvageRequest__form-piecesCount">{{ salvageData?.osdImage?.length }}</span>
      </p>

      <div>
        <mat-form-field class="salvageRequest__form-formField salvageRequest__form-requestName" appearance="outline">
          <mat-label>Request Name</mat-label>
          <input matInput [formControlName]="SalvageFormNames.RequestName" placeholder="SIC, Date, UID" autocomplete="off" />
        </mat-form-field>
      </div>

      <div *ngIf="salvageData.statusCd !== 'RequestForFeedback' && salvageData.statusCd !== 'RequestForPhotos' && salvageData.statusCd !== 'InspectionReportNeeded'">
        <mat-form-field appearance="outline" class="salvageRequest__form-formField salvageRequest__form-requestType">
          <mat-label>Request Type</mat-label>
          <input type="text" matInput autocomplete="off" placeholder="Select" [formControlName]="SalvageFormNames.RequestType" />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="salvageRequest__form-formField salvageRequest__form-sic" appearance="outline">
          <mat-label>SIC</mat-label>
          <input matInput [formControlName]="SalvageFormNames.SIC" autocomplete="off" />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="salvageRequest__form-formField salvageRequest__form-pdct-desc" appearance="outline">
          <mat-label>Product Description</mat-label>
          <textarea matInput [formControlName]="SalvageFormNames.FreightDescription" maxlength="4000" placeholder="Product Description"></textarea>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="salvageRequest__form-formField salvageRequest__form-ref" appearance="outline">
          <mat-label>Reference #'s (Model #, Serial #, UPC #) </mat-label>
          <input matInput [formControlName]="SalvageFormNames.Ref" maxlength="4000" autocomplete="off" />
        </mat-form-field>

        <div class="salvageRequest__form-duoPanel">
          <mat-form-field appearance="outline" class="salvageRequest__form-formField salvageRequest__form-brand">
            <mat-label>Brand<span class="mandatory-field">*</span></mat-label>
            <input type="text" matInput autocomplete="off" placeholder="Select" [formControlName]="SalvageFormNames.Brand" [matAutocomplete]="brandAutocomplete" />
            <mat-autocomplete #brandAutocomplete="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let brand of filteredOptionsBrand$ | async" [value]="brand">{{ brand }}</mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="getControl(SalvageFormNames.Brand).touched && getControl(SalvageFormNames.Brand).invalid">
              <div *ngIf="getControl(SalvageFormNames.Brand).errors.required">Brand is required</div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="salvageRequest__form-duoPanel">
          <mat-form-field appearance="outline" class="salvageRequest__form-formField salvageRequest__form-packagingType">
            <mat-label>Packaging Type<span class="mandatory-field">*</span></mat-label>
            <input matInput enforceAutocompleteSelection placeholder="Packaging Type " [formControlName]="SalvageFormNames.PackagingType" [matAutocomplete]="pkgCd" />
            <mat-autocomplete #pkgCd="matAutocomplete" [autoActiveFirstOption]="true">
              <mat-option *ngFor="let option of packagingListOptions" [value]="option"> {{ option }} </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="getControl(SalvageFormNames.PackagingType).touched && getControl(SalvageFormNames.PackagingType).invalid">
              <div *ngIf="getControl(SalvageFormNames.PackagingType).errors.required">Packaging Type is required</div>
            </mat-error>
          </mat-form-field>

          <div class="salvageRequest__form-duoPanel-specialHandling">
            <div class="salvageRequest__form-duoPanel-specialHandling-heading">
              <label>Special Handling<span class="mandatory-field">*</span></label>
              <mat-icon class="salvageRequest__form-duoPanel-specialHandling-info" matTooltip="{{ specialHandlingTooltip }}" [matTooltipPosition]="spHandlingTooltipPosition">info</mat-icon>
            </div>
            <mat-radio-group class="salvageRequest__form-duoPanel-specialHandling-radioButtons" [formControlName]="SalvageFormNames.SpecialHandling">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div>
          <mat-form-field class="salvageRequest__form-formField salvageRequest__form-totalWeight" appearance="outline">
            <mat-label>Total Weight<span class="mandatory-field">*</span></mat-label>
            <input matInput [formControlName]="SalvageFormNames.TotalWeight" type="number" placeholder="lbs" autocomplete="off" />
            <mat-error *ngIf="getControl(SalvageFormNames.TotalWeight).touched && getControl(SalvageFormNames.TotalWeight).invalid">
              <div *ngIf="getControl(SalvageFormNames.TotalWeight).errors.maxlength">Cannot accept more than 11 digits</div>
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <h6>Dimensions</h6>
          <div class="salvageRequest__form-dimension">
            <mat-form-field class="salvageRequest__form-formField" appearance="outline">
              <mat-label>Length </mat-label>
              <input matInput [formControlName]="SalvageFormNames.LengthNbr" placeholder="Length" autocomplete="off" />
              <mat-error *ngIf="getControl(SalvageFormNames.LengthNbr).touched && getControl(SalvageFormNames.LengthNbr).invalid">
                <div *ngIf="getControl(SalvageFormNames.LengthNbr).errors.maxlength">Limit 7 digits</div>
                <div *ngIf="getControl(SalvageFormNames.LengthNbr).errors.pattern">Only numbers allowed</div>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="salvageRequest__form-formField" appearance="outline">
              <mat-label>Width </mat-label>
              <input matInput [formControlName]="SalvageFormNames.WidthNbr" placeholder="Width" autocomplete="off" />
              <mat-error *ngIf="getControl(SalvageFormNames.WidthNbr).touched && getControl(SalvageFormNames.WidthNbr).invalid">
                <div *ngIf="getControl(SalvageFormNames.WidthNbr).errors.maxlength">Limit 7 digits</div>
                <div *ngIf="getControl(SalvageFormNames.WidthNbr).errors.pattern">Only numbers allowed</div>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="salvageRequest__form-formField" appearance="outline">
              <mat-label>Height </mat-label>
              <input matInput [formControlName]="SalvageFormNames.HeightNbr" placeholder="Height" autocomplete="off" />
              <mat-error *ngIf="getControl(SalvageFormNames.HeightNbr).touched && getControl(SalvageFormNames.HeightNbr).invalid">
                <div *ngIf="getControl(SalvageFormNames.HeightNbr).errors.maxlength">Limit 7 digits</div>
                <div *ngIf="getControl(SalvageFormNames.HeightNbr).errors.pattern">Only numbers allowed</div>
              </mat-error>
            </mat-form-field>
          </div>
          <h6>or</h6>
          <mat-form-field class="salvageRequest__form-formField" appearance="outline">
            <mat-label>Cubic ft </mat-label>
            <input matInput [formControlName]="SalvageFormNames.CubeNbr" placeholder="Cubic ft." autocomplete="off" />
            <mat-error *ngIf="getControl(SalvageFormNames.CubeNbr).touched && getControl(SalvageFormNames.CubeNbr).invalid">
              <div *ngIf="getControl(SalvageFormNames.CubeNbr).errors.maxlength">Limit 7 digits</div>
              <div *ngIf="getControl(SalvageFormNames.CubeNbr).errors.pattern">Only numbers allowed</div>
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" class="salvageRequest__form-formField salvageRequest__form-quality">
            <mat-label>Quality of Freight<span class="mandatory-field">*</span></mat-label>
            <mat-select [formControlName]="SalvageFormNames.QualityofFreight">
              <mat-option *ngFor="let quality of qualityTypes" [value]="quality">
                {{ QualityCdEnum[quality] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" class="salvageRequest__form-formField salvageRequest__form-value">
            <mat-label>Value of Freight<span class="mandatory-field">*</span></mat-label>
            <mat-select [formControlName]="SalvageFormNames.ValueofFreight">
              <mat-option *ngFor="let valueofFreight of valueTypes" [value]="valueofFreight">
                {{ valueofFreight }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" class="salvageRequest__form-formField salvageRequest__form-comments">
            <mat-label>Previous Comments</mat-label>
            <textarea matInput [formControlName]="SalvageFormNames.OldComments" placeholder="Enter more details"></textarea>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" class="salvageRequest__form-formField salvageRequest__form-comments">
            <mat-label>Comments<span class="mandatory-field">*</span></mat-label>
            <textarea matInput [formControlName]="SalvageFormNames.Comments" placeholder="Enter more details"></textarea>
          </mat-form-field>
          <mat-error class="salvageRequest__form-comments-error" *ngIf="getControl(SalvageFormNames.Comments).touched && getControl(SalvageFormNames.Comments).invalid">
            <div *ngIf="getControl(SalvageFormNames.Comments).errors.maxlength">Limit 4000 digits</div>
          </mat-error>
        </div>
      </div>
      <div class="salvageRequest__form-bottomPanel">
        <button (click)="salvageFormUpdate()" mat-flat-button xpoLargeButton xpoButton color="primary" class="salvageRequest__form-submit" [disabled]="!editSalvageForm.valid">
          Update
        </button>

        <button (click)="salvageFormCancel()" mat-stroked-button xpoLargeButton xpoButton color="primary" class="salvageRequest__form-cancel" type="reset">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
