import { Component } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigManagerProperties } from './enums/config-manager-properties.enum';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoLtlFeaturesService, XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { delay, retryWhen, take } from 'rxjs/operators';
import { User } from '@xpo-ltl/sdk-common';
import { UserRoleService } from './services/user-role.service';
import { RouterUriComponents } from './enums/router-uri-components.enum';
import { FeatureService } from './services/feature-service/feature.service';
import {
  FeatureSetting,
  ListLocationFeaturesQuery,
  ListLocationFeaturesResp,
  ListLocationFeatureValuesByFeaturePath,
  ListLocationFeatureValuesByFeatureQuery,
  ListLocationFeatureValuesByFeatureResp,
  LocationApiService,
} from '@xpo-ltl-2.0/sdk-location';
export interface TabItem {
  label: string;
  route: string;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title: string;
  build: string;
  isProduction: boolean;

  propertyCode:string='SLVG_ON_OSD';

  tabs: TabItem[] = [
    {
      label: 'Overage',
      route: RouterUriComponents.OVERAGE,
    },
    {
      label: 'Salvage',
      route: RouterUriComponents.SALVAGE,
    },
  ];

  constructor(
    private configManagerService: ConfigManagerService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private authService: XpoLtlAuthenticationService,
    private loginService: XpoLtlLoggedInUserService,
    private userRoleService: UserRoleService,
    private featureServiceFlag:FeatureService,
    private locationApiService:LocationApiService
  ) {
    iconRegistry.addSvgIcon('xpo-logo', sanitizer.bypassSecurityTrustResourceUrl('./assets/images/xpo_logo.svg'));
    this.title = configManagerService.getSetting<string>(ConfigManagerProperties.appName);
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);

    this.isProduction = this.configManagerService.getSetting<boolean>('production');

    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {
      this.loginService.userLoggedIn$.subscribe(() => {
        this.handleLoggedInUser();
      });
    });
  }

  private handleLoggedInUser(): void {
    this.loginService
      .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .pipe(retryWhen(errors => errors.pipe(delay(1000), take(5))))
      .subscribe(
        (user: User) => {
          if (user) {
            this.userRoleService.setIsUserApprover(user);
            this.setFeatureFlag(user)
          }
        },
        error => {
          console.log('ERROR', error);
        }
      );
  }
  
  private setFeatureFlag(user: User) {
   
    const request = new ListLocationFeaturesQuery();
    request.featureCd = this.propertyCode;

    this.locationApiService.listLocationFeatures(request)
     .pipe(take(1)).subscribe((res:ListLocationFeaturesResp)=>{
          const featureData=res.locationFeatures[0];

          let flag=featureData.settingValue==='Y';

          this.featureServiceFlag.setFeature(flag);
          if(!flag){
            this.tabs=this.tabs.filter((val)=>val.label!=='Salvage')
          } 
     })
  }
}
