import { AfterViewInit, Directive, Host, Input, OnDestroy, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { takeUntil } from 'rxjs/operators';
​
@Directive({
  selector: '[enforceAutocompleteSelection]',
})
export class EnforceAutocompleteSelectionDirective implements AfterViewInit, OnDestroy {
  private unsubscriber: Unsubscriber = new Unsubscriber();
​
  @Input()
  matAutocomplete: MatAutocomplete;
​
  constructor(
    @Host() @Self() private readonly autoCompleteTrigger: MatAutocompleteTrigger,
    private readonly ngControl: NgControl
  ) {}
​
  ngAfterViewInit() {
    this.autoCompleteTrigger.panelClosingActions.pipe(takeUntil(this.unsubscriber.done$)).subscribe((e) => {
      if (!e || !e.source) {
        const selected = this.matAutocomplete.options
          .map((option) => option.value)
          .find((option) => {
            if (this.ngControl && this.ngControl.value) {
              return (option.code ? option.code : option) === this.ngControl.value.toString().toUpperCase();
            } else {
              return false;
            }
          });
​
        if (!selected && !(this.ngControl.value && this.ngControl.value.code)) {
          this.ngControl.control.setValue('');
        }
      }
    });
  }
​
  ngOnDestroy() {
    this.unsubscriber.complete();
  }
}