import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as ProNbrFormatterUtils from '../utils/pro-number-formatter.utils';
import * as _ from 'lodash';

export function childProValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control || !control.value) {
      return null;
    }

    if (!isValidProNumber(control.value)) {
      return { invalidProFormat: true };
    }
    return null;
  };
}

function isValidProNumber(inputValue) {
  return isValidProNumberCheckDigit(inputValue);
}

function isValidProNumberCheckDigit(inputValue) {
  const proNumber = conditionProNumber(inputValue);
  if (proNumber === '' || proNumber.length < 9 || proNumber.length > 11) {
    return false;
  }
  if (proNumber.substring(0, 3) === '000') {
    return false;
  }

  if (!ProNbrFormatterUtils.isValidChildPro(proNumber)) {
    return false;
  }

  if (proNumber.length === 10) {
    return ProNbrFormatterUtils.isValidChildPro(proNumber);
  }
  // divide first 8 chars by 7 multiple remainder by 7
  const checkDigit = Number(proNumber.substring(8));
  const number = Number.parseInt(proNumber.substring(0, 8));
  const mod = number % 7;
  return mod === checkDigit;
}

function conditionProNumber(proNumber = '', digits = 9) {
  if (proNumber === '' || proNumber === null) {
    return '';
  }
  proNumber = ProNbrFormatterUtils.replaceNonDigitChars(proNumber);
  proNumber = convert(proNumber, digits);
  return proNumber;
}

function convert(proNumber = '', digits = 9) {
  let part1: string, part2: string, trimmed: string;
  trimmed = ProNbrFormatterUtils.trimChars(proNumber);

  if (trimmed.length < 9) {
    // if it had a leading 0 as part of the real number
    if (trimmed.length < 8) {
      trimmed = `00${trimmed}`;
    } else {
      trimmed = `0${trimmed}`;
    }
  }
  part1 = trimmed.slice(0, 3);
  part2 = trimmed.slice(3);
  part2 = part2.length > 6 ? ProNbrFormatterUtils.trimChars(part2) : part2;

  let retValue = '';
  if (digits === 11) {
    retValue = `0${part1}0${part2}`;
  } else if (digits === 10) {
    retValue = `${part1}-${part2}`;
  } else {
    retValue = part1.concat(part2);
  }

  return retValue;
}
