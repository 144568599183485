import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageUploadService {
  private imageUploadEvent = new BehaviorSubject<any>({});
  public uploadEvent = this.imageUploadEvent.asObservable();

  constructor() {}

  setEvent(event: any) {
    this.imageUploadEvent.next(event);
  }
}
