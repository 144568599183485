import { Injectable } from '@angular/core';
import { ListBrandsForSalvageQuery, ListSalvageRequestNotesPath, ListSalvageRequestsRqst, ShipmentApiService } from '@xpo-ltl-2.0/sdk-shipment';
import { BehaviorSubject } from 'rxjs';
import { SalvageRequestStatusResponseTemplate } from '../../enums/salvage-request-status-response-template.enum';
import { SalvageRequestTypes } from '../../enums/salvage-request-type-cd.enum';

@Injectable({
  providedIn: 'root'
})
export class SalvageRequesterService {

  private brandListSubject = new BehaviorSubject<string[]>([]);
  public brandList$ = this.brandListSubject.asObservable();

  constructor(
    private shipmentApiService: ShipmentApiService
  ) { }

  public getSalvageRequests(listSalvageQuery: ListSalvageRequestsRqst){
    const updatedDispositionType = SalvageRequestTypes[listSalvageQuery?.salvageRequest?.salvageRequestTypeCd];
    if(updatedDispositionType){
      listSalvageQuery.salvageRequest.salvageRequestTypeCd = updatedDispositionType;
    }
    const updatedRequestStatus = SalvageRequestStatusResponseTemplate[listSalvageQuery?.salvageRequest?.statusCd];
    if(updatedRequestStatus){
      listSalvageQuery.salvageRequest.statusCd = updatedRequestStatus;
    }
    return this.shipmentApiService.listSalvageRequests(listSalvageQuery);
  }

  public getSalvageRequestNotes(request:ListSalvageRequestNotesPath){
    return this.shipmentApiService.listSalvageRequestNotes(request);
  }

  public listSalvageBrands(queryParams: ListBrandsForSalvageQuery){
    return this.shipmentApiService.listBrandsForSalvage(queryParams);
  }

  public setBrandList(brandList: string[]): void {
    this.brandListSubject.next(brandList);
  }

}
