import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { SalvageFormNames } from '../../enums/salvage-form-names.enum';
import { EditSalvageService } from '../../services/edit-salvage.service';
import { toString as _toString, startsWith as _startsWith } from 'lodash';
import { SalvageRequestService } from '../../services/salvage-request-service/salvage-request.service';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { CommodityPackageCdHelper, Envelope } from '@xpo-ltl/sdk-common';
import { CommodityPackageCdPipe } from '../../pipes/commodity-package-cd.pipe';
import { FreightValueType } from '../../enums/freight-value-type.enum';
import { QualityCdEnum } from '../../enums/quality-cd.enum';
import { OsdImage, UpdateSalvageRequestResp } from '@xpo-ltl-2.0/sdk-shipment';
import { NotificationService } from '@xpo-ltl/data-api';
import { SalvageRequesterService } from '../../services/salvage/salvage-requester.service';

@Component({
  selector: 'app-edit-salvage-request',
  templateUrl: './edit-salvage-request.component.html',
  styleUrls: ['./edit-salvage-request.component.scss'],
})
export class EditSalvageRequestComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<EditSalvageRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public salvageData: any,
    private editSalvageService: EditSalvageService,
    private notificationService: NotificationService,
    private salvageRequesterService: SalvageRequesterService
  ) {}

  public editSalvageForm: FormGroup;
  readonly SalvageFormNames = SalvageFormNames;
  readonly specialHandlingTooltip: string = 'Are the contents of this incident in any way qualified as DANGEROUS, HAZARDOUS, Rx, LICENSED or REGULATED?';
  readonly spHandlingTooltipPosition = 'after';
  readonly QualityCdEnum = QualityCdEnum;
  public requestTypes = ['Common Disposal', 'Company Use', 'Canada Salvage', 'Regulated Disposal', 'United State Salvage'];
  public qualityTypes = ['0', '1', '2', '3', '4', '5'];
  public valueTypes = ['Trash', '$1-$149', '$150-$499', '$500-$999', '$1,000-$4,999', '$5,000 or more'];
  public defaultErrorMessage = 'Error while Updating Salvage Request';

  filteredOptionsBrand$: Observable<String[]>;
  public packagingListOptions: string[] = CommodityPackageCdHelper.values()
    .sort()
    .map(v => CommodityPackageCdPipe.prototype.transform(v));

  public brandList = [];

  private unsubscriber: Unsubscriber = new Unsubscriber();

  ngOnInit(): void {
    this.initForm();
    this.setBrandList();
    this.setOptions();
    this.handleDimensions();
  }

  private decimalNumberPattern = '^[0-9]+(.[0-9]+)?$';

  initForm(): void {
    this.editSalvageForm = new FormGroup({
      [SalvageFormNames.RequestName]: new FormControl({ value: this.salvageData.salvageRequestName, disabled: true }),
      [SalvageFormNames.RequestType]: new FormControl({ value: this.salvageData.salvageRequestTypeCd, disabled: true }),
      [SalvageFormNames.SIC]: new FormControl({ value: this.salvageData.sicCd, disabled: true }),
      [SalvageFormNames.FreightDescription]: new FormControl({ value: this.salvageData.freightDescription, disabled: true }, Validators.required),
      [SalvageFormNames.Ref]: new FormControl({ value: this.getReference(this.salvageData.osdImage), disabled: true }),
      [SalvageFormNames.Brand]: new FormControl({ value: this.salvageData.brand, disabled: false }, Validators.required),
      [SalvageFormNames.PackagingType]: new FormControl({ value: this.getPackagingCd(this.salvageData.packagingCd), disabled: false }, Validators.required),
      [SalvageFormNames.SpecialHandling]: new FormControl({ value: this.salvageData.specialHandlingInd, disabled: false }, Validators.required),
      [SalvageFormNames.TotalWeight]: new FormControl({ value: this.salvageData.weightLbs, disabled: false }, [Validators.maxLength(11), Validators.required]),
      [SalvageFormNames.LengthNbr]: new FormControl('', [Validators.maxLength(7), Validators.pattern(this.decimalNumberPattern)]),
      [SalvageFormNames.WidthNbr]: new FormControl('', [Validators.maxLength(7), Validators.pattern(this.decimalNumberPattern)]),
      [SalvageFormNames.HeightNbr]: new FormControl('', [Validators.maxLength(7), Validators.pattern(this.decimalNumberPattern)]),
      [SalvageFormNames.CubeNbr]: new FormControl('', [Validators.maxLength(7), Validators.pattern(this.decimalNumberPattern)]),
      [SalvageFormNames.QualityofFreight]: new FormControl({ value: this.salvageData.freightConditionCd, disabled: false }, Validators.required),
      [SalvageFormNames.ValueofFreight]: new FormControl({ value: this.getKeyByValue(FreightValueType, this.salvageData.estimatedValueCd), disabled: false }, Validators.required),
      [SalvageFormNames.OldComments]: new FormControl({ value: this.salvageData.comment, disabled: true }),
      [SalvageFormNames.Comments]: new FormControl('', [Validators.required, Validators.maxLength(4000)]),
    });
  }

  private getPackagingCd(packagingCd: string): string {
    return this.packagingListOptions.find(packaging => packaging.split('-')[0].trim() === packagingCd);
  }

  private setOptions() {
    this.filteredOptionsBrand$ = this.editSalvageForm.get(SalvageFormNames.Brand).valueChanges.pipe(
      takeUntil(this.unsubscriber.done$),
      startWith(''),
      map(value => {
        const filterValue = _toString(value).toLowerCase();
        return this.brandList.filter(brand => _startsWith(brand.toLowerCase(), filterValue)).slice(0, 10);
      })
    );
  }

  private setBrandList() {
    this.salvageRequesterService.brandList$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((resp: string[]) => {
      this.brandList = resp;
    });
  }

  getControl(controlName: string): FormControl {
    return this.editSalvageForm.get(controlName) as FormControl;
  }

  getReference(osdImage: OsdImage[]): string {
    if (!osdImage) {
      return '';
    }
    let reference = osdImage
      .map(imageHeader => imageHeader.referenceNbr)
      .filter(referenceNbr => !!referenceNbr)
      .join('; ');
    return reference;
  }

  private handleDimensions() {
    const cubeNbrValue = +this.salvageData.cubeNbr;
    const widthNbrValue = +this.salvageData.widthNbr.toString();
    const lengthNbrValue = +this.salvageData.lengthNbr.toString();
    const heightNbrValue = +this.salvageData.heightNbr.toString();
    if ((widthNbrValue && widthNbrValue > 0) || (lengthNbrValue && lengthNbrValue > 0) || (heightNbrValue && heightNbrValue > 0)) {
      this.editSalvageForm.get(this.SalvageFormNames.WidthNbr).setValue(widthNbrValue > 0 ? widthNbrValue : '');
      this.editSalvageForm.get(this.SalvageFormNames.LengthNbr).setValue(lengthNbrValue > 0 ? lengthNbrValue : '');
      this.editSalvageForm.get(this.SalvageFormNames.HeightNbr).setValue(heightNbrValue > 0 ? heightNbrValue : '');
      this.editSalvageForm.get(this.SalvageFormNames.CubeNbr).setValue(cubeNbrValue > 0 ? cubeNbrValue : '');
      this.editSalvageForm.get(this.SalvageFormNames.CubeNbr).disable();
    } else if (cubeNbrValue && cubeNbrValue > 0) {
      this.editSalvageForm.get(this.SalvageFormNames.CubeNbr).setValue(cubeNbrValue);
      this.editSalvageForm.get(this.SalvageFormNames.LengthNbr).disable();
      this.editSalvageForm.get(this.SalvageFormNames.WidthNbr).disable();
      this.editSalvageForm.get(this.SalvageFormNames.HeightNbr).disable();
    }

    this.editSalvageForm.get(SalvageFormNames.LengthNbr).valueChanges.subscribe(value => {
      this.dimensionsChecker();
    });

    this.editSalvageForm.get(SalvageFormNames.WidthNbr).valueChanges.subscribe(value => {
      this.dimensionsChecker();
    });

    this.editSalvageForm.get(SalvageFormNames.HeightNbr).valueChanges.subscribe(value => {
      this.dimensionsChecker();
    });

    this.editSalvageForm.get(SalvageFormNames.CubeNbr).valueChanges.subscribe(curentCubicFtValue => {
      if (curentCubicFtValue?.length > 0) {
        this.editSalvageForm.get(SalvageFormNames.LengthNbr)?.enabled ? this.editSalvageForm.get(this.SalvageFormNames.LengthNbr).disable() : null;
        this.editSalvageForm.get(SalvageFormNames.WidthNbr)?.enabled ? this.editSalvageForm.get(this.SalvageFormNames.WidthNbr).disable() : null;
        this.editSalvageForm.get(SalvageFormNames.HeightNbr)?.enabled ? this.editSalvageForm.get(this.SalvageFormNames.HeightNbr).disable() : null;
      }
      if (curentCubicFtValue?.length === 0 || !curentCubicFtValue) {
        this.editSalvageForm.get(SalvageFormNames.LengthNbr)?.disabled ? this.editSalvageForm.get(this.SalvageFormNames.LengthNbr)?.enable() : null;
        this.editSalvageForm.get(SalvageFormNames.WidthNbr)?.disabled ? this.editSalvageForm.get(this.SalvageFormNames.WidthNbr).enable() : null;
        this.editSalvageForm.get(SalvageFormNames.HeightNbr)?.disabled ? this.editSalvageForm.get(this.SalvageFormNames.HeightNbr).enable() : null;
      }
    });
  }

  dimensionsChecker() {
    const currentLengthValue = this.editSalvageForm.get(SalvageFormNames.LengthNbr).value;
    const currentWidthValue = this.editSalvageForm.get(SalvageFormNames.WidthNbr).value;
    const currentHeightValue = this.editSalvageForm.get(SalvageFormNames.HeightNbr).value;

    if (currentLengthValue?.toString()?.length > 0 || currentWidthValue?.toString()?.length > 0 || currentHeightValue?.toString()?.length > 0) {
      this.editSalvageForm.get(SalvageFormNames.CubeNbr).disable();
    }
    if (currentLengthValue?.toString()?.length === 0 && currentWidthValue?.toString()?.length === 0 && currentHeightValue?.toString()?.length === 0) {
      if (this.editSalvageForm.get(SalvageFormNames.CubeNbr)?.disabled) {
        this.editSalvageForm.get(SalvageFormNames.CubeNbr).enable();
      }
    }
    if (!currentLengthValue && !currentWidthValue && !currentHeightValue) {
      if (this.editSalvageForm.get(SalvageFormNames.CubeNbr)?.disabled) {
        this.editSalvageForm.get(SalvageFormNames.CubeNbr).enable();
      }
    }
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  salvageFormUpdate(): void {
    if (this.editSalvageForm.invalid) {
      this.editSalvageForm.markAllAsTouched();
      return;
    } else {
      this.editSalvageService
        .updateSalvageRequest(this.editSalvageForm.value, this.salvageData)
        .pipe(take(1))
        .subscribe(
          (resp: Envelope<UpdateSalvageRequestResp>) => {
            this.editSalvageService.setSalvageEditSubject(true);
          },
          error => {
            this.notificationService.showSnackBarMessage(error?.error?.moreInfo?.[0]?.message ?? this.defaultErrorMessage, { durationInMillis: 3000, status: 'ERROR' });
          }
        );
    }
  }

  salvageFormCancel() {
    this.dialogRef.close();
  }

  displayFn(type): string | null {
    return type ? type : null;
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
}
