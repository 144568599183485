import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Annotation } from '../interfaces/Annotation';
import { ImagesLabelResponse } from '../interfaces/ImagesLabelResponse';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private event: Object;
  private imageSearchRefreshSubject = new BehaviorSubject<Boolean>(false);
  public imageSearchRefresh$ = this.imageSearchRefreshSubject.asObservable();

  constructor(private http: HttpClient, private configManagerService: ConfigManagerService) {}

  public uploadImage(image: any): Observable<object> {
    const apiURL = this.configManagerService.getSetting<string>(ConfigManagerProperties.apiUrl);
    const imagesEndpoint = this.configManagerService.getSetting<string>(ConfigManagerProperties.imagesEndPoint);
    const url = apiURL + imagesEndpoint;

    let encodedImage = '';
    if (typeof image != 'string') {
      encodedImage = image.img
        .replace('data:image/jpeg;base64,', '')
        .replace('data:image/png;base64,', '')
        .replace('data:image/tiff;base64,', '');
    } else if (image) {
      encodedImage = image
        .replace('data:image/jpeg;base64,', '')
        .replace('data:image/png;base64,', '')
        .replace('data:image/tiff;base64,', '');
    }

    const data = {
      requests: [
        {
          image: {
            content: encodedImage,
          },
          features: [
            {
              type: 'LABEL_DETECTION',
              maxResults: 5,
            },
            {
              type: 'LOGO_DETECTION',
              maxResults: 5,
            },
            {
              type: 'TEXT_DETECTION',
              maxResults: 20,
            },
          ],
        },
      ],
    };

    // return this.http.post('https://vision.googleapis.com/v1/images:annotate?key=AIzaSyDYfETZnlTUdMiQa0FFZYrC_eJhZT_3lPc', data);
    return this.http.post(url, data);
  }

  transformToModel(response: any): string[] {
    const labels: string[] = [];
    let uniqueLabels: string[] = [];

    if (response?.data?.imageResponse?.length > 0) {
      const labelAnnotations = response.data.imageResponse[0].labelAnnotations;
      const textAnnotation = response.data.imageResponse[0].textAnnotations;
      if (textAnnotation) {
        textAnnotation.map(data => {
          labels.push(data.description);
        });
      }

      const logoAnnotation = response.data.imageResponse[0].logoAnnotations;
      if (logoAnnotation) {
        logoAnnotation.map(data => {
          labels.push(data.description);
        });
      }

      if (labelAnnotations) {
        labelAnnotations.map(data => {
          labels.push(data.description);
        });
      }
    }
    if (labels) {
      labels.forEach(label => {
        if (uniqueLabels.indexOf(label.toUpperCase()) == -1) {
          uniqueLabels.push(label.toUpperCase().trim());
        }
      });
    }

    return uniqueLabels;
  }

  private extractImageContent(image: string) {
    if (image) {
      return image
        .replace('data:image/jpeg;base64,', '')
        .replace('data:image/png;base64,', '')
        .replace('data:image/tiff;base64,', '');
    }
    return null;
  }

  setImageSearchRefreshSubject(newValue: Boolean){
    this.imageSearchRefreshSubject.next(newValue);
  }
}
