<mat-dialog-content>
  <div class="container-fluid tags">
    <form [formGroup]="form">
      <div class="form-title">Suggested Tags</div>

      <label formArrayName="labels" *ngFor="let label of form.get('labels')?.controls; let i = index">
        <mat-card class="labelOptions"> <input type="checkbox" class="selectbox" [formControlName]="i" />{{ labels[i] }} </mat-card>
      </label>
    </form>

    <mat-dialog-actions class="action">
      <button mat-button color="primary" type="button" mat-dialog-close>Cancel</button> <button mat-button color="primary" mat-raised-button type="submit" (click)="handleSave()">Add</button>
    </mat-dialog-actions>
  </div>
</mat-dialog-content>
