import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: '[appDecimalNumber]' })
export class DecimalNumberDirective {
  decimalSeparator: string = '.';

  previousValue: string = '';

  decimal: string = '^[0-9]+(.[0-9]+)?$';

  constructor(private hostElement: ElementRef) {}

  @HostListener('change', ['$event']) onChange(e) {
    this.validateValue(this.hostElement.nativeElement.value);
  }

  @HostListener('paste', ['$event']) onPaste(e) {
    let value = e.clipboardData.getData('text/plain');
    this.validateValue(value);
    e.preventDefault();
  }

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    let originalValue: string = e.target['value'];
    let key: string = this.getName(e);
    let controlOrCommand = e.ctrlKey === true || e.metaKey === true;

    // allowed keys apart from numeric characters
    let allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Escape', 'Tab'];

    // allow some non-numeric characters
    if (
      allowedKeys.indexOf(key) != -1 ||
      // Allow: Ctrl+A and Command+A
      (key == 'a' && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (key == 'c' && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (key == 'v' && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (key == 'x' && controlOrCommand)
    ) {
      // let it happen, don't do anything
      return;
    }

    this.previousValue = originalValue;
  }

  validateValue(value: string): void {
    // when a numbers begins with a decimal separator,
    // fix it adding a zero in the beginning
    let firstCharacter = value.charAt(0);
    if (firstCharacter == this.decimalSeparator) value = 0 + value;

    // when a numbers ends with a decimal separator,
    // fix it adding a zero in the end
    let lastCharacter = value.charAt(value.length - 1);
    if (lastCharacter == this.decimalSeparator) value = value + 0;

    // test number with regular expression, when
    // number is invalid, replace it with a zero
    let valid: boolean = new RegExp(this.decimal).test(value);
    this.hostElement.nativeElement['value'] = valid ? value : '';
  }

  getName(e): string {
    if (e.key) {
      return e.key;
    } else {
      // for old browsers
      if (e.keyCode && String.fromCharCode) {
        switch (e.keyCode) {
          case 8:
            return 'Backspace';
          case 9:
            return 'Tab';
          case 27:
            return 'Escape';
          case 37:
            return 'ArrowLeft';
          case 39:
            return 'ArrowRight';
          case 190:
            return '.';
          default:
            return String.fromCharCode(e.keyCode);
        }
      }
    }
  }
}
