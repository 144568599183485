import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { Unsubscriber, XpoLtlEmailService, XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { ErrorMessageParser, NotificationService } from '@xpo-ltl/data-api';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';
import { FeedbackConfigInterface } from '@xpo-ltl/ngx-ltl-core';
import { XpoAuthenticationService } from '@xpo/ngx-auth';
import { catchError, delay, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { User } from '@xpo-ltl/sdk-common';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService implements OnDestroy {
  private feedbackConfigBehaviorSubject = new BehaviorSubject<FeedbackConfigInterface>(undefined);
  feedbackConfig$ = this.feedbackConfigBehaviorSubject.asObservable();

  private unsubscribe = new Unsubscriber();

  constructor(
    private loginService: XpoLtlLoggedInUserService,
    private configManagerService: ConfigManagerService,
    private emailService: XpoLtlEmailService,
    private notificationService: NotificationService,
    private xpoAuthenticationService: XpoAuthenticationService,
  ) {
    const generateFeedbackConfig = (user): FeedbackConfigInterface => {
      return {
        disableFromNameInput: true,
        disableSubjectInput: true,
        subject: this.configManagerService.getSetting(ConfigManagerProperties.feedbackSubject),
        fromEmail: user.emailAddress,
        fromName: `${user.givenName || user.displayName} ${user.lastName}`,
        onSendCallback: (message): Observable<boolean> => {
          return this.sendEmail(message);
        }
      };
    };


    this.xpoAuthenticationService
      .isLoggedIn$()
      .pipe(
        distinctUntilChanged(),
        delay(100),
        switchMap((loggedIn: boolean) => {
          return !loggedIn
            ? of(undefined)
            : this.loginService
              .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
              .pipe(
                catchError((error) => {
                  console.log('USER LOGIN ERROR:', error);
                  return of(undefined);
                })
              );
        }),
        takeUntil(this.unsubscribe.done$)
      )
      .subscribe((user: User) => {
        if (user) {
          this.feedbackConfigBehaviorSubject.next(generateFeedbackConfig(user));
        }
      });
  }

  private sendEmail(message): Observable<boolean> {
    const subject = new Subject<boolean>();

    this.emailService
      .sendEmail(
        this.feedbackConfigBehaviorSubject.value.fromName,
        this.feedbackConfigBehaviorSubject.value.fromEmail,
        this.configManagerService.getSetting(ConfigManagerProperties.feedbackToAddress),
        this.configManagerService.getSetting(ConfigManagerProperties.feedbackSubject),
        `${message} - ${this.configManagerService.getSetting(ConfigManagerProperties.appName)} - ${
          this.feedbackConfigBehaviorSubject.value.fromName
        }`
      )
      .subscribe(
        () => {
          this.notificationService.showSnackBarMessage('Feedback sent', {
            durationInMillis: 3000,
            status: 'success'
          });

          subject.next(true);
          subject.complete();
        },
        error => {
          this.notificationService.showSnackBarMessage(`${ErrorMessageParser.parseMessage(error) || error}.`, {
            durationInMillis: 3000,
            status: 'error'
          });

          subject.next(false);
          subject.complete();
        }
      );

    return subject;
  }

  ngOnDestroy(): void {
    this.unsubscribe.complete();
  }
}
