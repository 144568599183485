export enum SalvageRequestApproverGridColumn {
  RequestId = 'salvageRequestId',
  WorkingBy = 'auditInfo.updateById',
  Disposition = 'salvageRequestTypeCd',
  HoldForVendor = 'holdForVendorInd',
  AbeyanceInd = 'abeyanceInd',
  ReasonType = 'qualifyTypeCd',
  StatusCode = 'statusCd',
  EstimatedValue = 'estimatedValueCd',
  Requester = 'auditInfo.createdById',
  OriginSic = 'sicCd',
  SalvagePro = 'proNbr',
  RequestDate = 'auditInfo.createdTimestamp',
  Updated = 'auditInfo.updatedTimestamp',
  Brand = 'brand',
}
