import { Injectable } from '@angular/core';
import { SalvageRequest, ShipmentApiService, UpdateSalvageRequestResp, UpdateSalvageRequestRqst } from '@xpo-ltl-2.0/sdk-shipment';
import { Envelope } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FreightValueType } from '../enums/freight-value-type.enum';
import { SalvageFormNames } from '../enums/salvage-form-names.enum';

@Injectable({
  providedIn: 'root'
})
export class EditSalvageService {

  private salvageEditRefreshSubject = new BehaviorSubject<Boolean>(false);
  public salvageEditRefresh$ = this.salvageEditRefreshSubject.asObservable();

  setSalvageEditSubject(newValue: Boolean){
    this.salvageEditRefreshSubject.next(newValue);
  }

  constructor(private shipmentApiService: ShipmentApiService) { }

  public updateSalvageRequest(formReq, origData): Observable<Envelope<UpdateSalvageRequestResp>>{
    const request: UpdateSalvageRequestRqst = {
      ...new UpdateSalvageRequestRqst(),
      salvageRequest: {
        ...new SalvageRequest(),
        salvageRequestId: origData.salvageRequestId,
        proNbr: origData.proNbr,
        brand: formReq?.[SalvageFormNames.Brand],
        packagingCd: formReq?.[SalvageFormNames.PackagingType].split('-')[0].trim(),
        specialHandlingInd: formReq?.[SalvageFormNames.SpecialHandling],
        weightLbs: +formReq?.[SalvageFormNames.TotalWeight],
        lengthNbr: isNaN(+formReq?.[SalvageFormNames.LengthNbr]) ? 0 : +formReq?.[SalvageFormNames.LengthNbr],
        widthNbr: isNaN(+formReq?.[SalvageFormNames.WidthNbr]) ? 0 : +formReq?.[SalvageFormNames.WidthNbr],
        heightNbr: isNaN(+formReq?.[SalvageFormNames.HeightNbr]) ? 0 : +formReq?.[SalvageFormNames.HeightNbr],
        cubeNbr: isNaN(+formReq?.[SalvageFormNames.CubeNbr]) ? 0 : +formReq?.[SalvageFormNames.CubeNbr],
        freightConditionCd: formReq?.[SalvageFormNames.QualityofFreight],
        estimatedValueCd: FreightValueType[formReq?.[SalvageFormNames.ValueofFreight]],
      },
      feedbackStatusRequest: "REQUESTOR_EDIT",
    };
    if(origData.comment){
      request.salvageRequest.comment = origData.comment + "\n" + formReq?.[SalvageFormNames.Comments];
    }
    else{
      request.salvageRequest.comment = formReq?.[SalvageFormNames.Comments];
    }
    return this.shipmentApiService.updateSalvageRequest(request);

  }
}
