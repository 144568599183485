import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-image-label-dialog',
  templateUrl: './image-label-dialog.component.html',
  styleUrls: ['./image-label-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageLabelDialogComponent implements OnInit {
  labels: any;
  form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) private dialogConfig: any, private dialogRef: MatDialogRef<ImageLabelDialogComponent>, private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.labels = this.dialogConfig.labels || [];

    const controls = this.labels.map(c => new FormControl(false));
    this.form = this.formBuilder.group({
      labels: new FormArray(controls),
    });
  }
  handleSave() {
    const selectedLabels = this.form.value.labels.map((v, i) => (v ? this.labels[i] : null)).filter(v => v !== null);
    this.dialogRef.close(selectedLabels);
  }
}
