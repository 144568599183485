import { AbstractControl } from '@angular/forms';

export function PackaginTypeValidator(validOptions: string[]) {
    return (control: AbstractControl) => {

        if (!control || !control.value) {
            return null;
        }

        if(!validOptions.includes(control.value)) {
            return {
                invalidOption: true
            }
        }

        return null;
    }
}
