import { Injectable, OnInit } from '@angular/core';
import { ListSalvageRequestsResp, ListSalvageRequestsRqst, SalvageRequest, ShipmentApiService, UpdateSalvageRequestResp, UpdateSalvageRequestRqst } from '@xpo-ltl-2.0/sdk-shipment';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoSortExpression } from '@xpo-ltl/ngx-ltl-board';
import { Envelope, ListInfo, SortField } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { FreightValueType } from '../../../enums/freight-value-type.enum';
import { SalvageRequestTypes } from '../../../enums/salvage-request-type-cd.enum';
import { get as _get } from 'lodash';
import { StatusUpdate } from '../../../interfaces/salvage-approver-status-update.interface';
import { SalvageRequestStatusResponseTemplate } from '../../../enums/salvage-request-status-response-template.enum';
import { NotificationService } from '@xpo-ltl/data-api';
import { DocumentManagementApiService, RetrieveDmsAuthTokenResp, RetrieveDmsAuthTokenRqst } from '@xpo-ltl/sdk-documentmanagement';
import { SalvageRequestApproverGridColumn } from '../../../enums/salvage-request-approver-grid-columns.enum';

@Injectable({
  providedIn: 'root',
})
export class SalvageRequestApproverService implements OnInit {
  refreshGridSubject = new Subject();
  refreshGrid$ = this.refreshGridSubject.asObservable();

  dispositionSubject = new BehaviorSubject<string>('');
  disposition$ = this.dispositionSubject.asObservable();

  requestStatusSubject = new BehaviorSubject<string>('');
  requestStatus$ = this.requestStatusSubject.asObservable();

  freightValueSubject = new BehaviorSubject<string>('');
  freightValue$ = this.freightValueSubject.asObservable();

  dateRangeFromSubject = new BehaviorSubject<string>('');
  dateRangeFrom$ = this.dateRangeFromSubject.asObservable();

  dateRangeToSubject = new BehaviorSubject<string>('');
  dateRangeTo$ = this.dateRangeToSubject.asObservable();

  searchFieldSubject = new BehaviorSubject<string>('');
  searchField$ = this.searchFieldSubject.asObservable();

  requestTypeSubject = new BehaviorSubject<string>('');
  requestType$ = this.requestTypeSubject.asObservable();

  requestTypeCountSubject = new BehaviorSubject<number>(0);

  unsubscriber = new Unsubscriber();

  apiResponseSubject = new BehaviorSubject<SalvageRequest>(null);
  apiResponse$ = this.apiResponseSubject.asObservable();

  showSpinnerSubject = new BehaviorSubject<boolean>(false);
  showSpinner$ = this.showSpinnerSubject.asObservable();

  listSalvageRequest: ListSalvageRequestsRqst = new ListSalvageRequestsRqst();

  constructor(private shipmentApiService: ShipmentApiService, private notificationService: NotificationService, private dmsService: DocumentManagementApiService) {
    combineLatest([this.disposition$, this.requestStatus$, this.freightValue$, this.dateRangeFrom$, this.dateRangeTo$, this.searchField$, this.requestType$])
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(([dispositionType, requestStatus, freightValue, dateRangeFrom, dateRangeTo, searchField, requestType]) => {
        this.listSalvageRequest = {
          salvageRequest: {
            salvageRequestTypeCd: dispositionType === '' ? null : SalvageRequestTypes[dispositionType],
            statusCd: requestStatus === '' ? null : SalvageRequestStatusResponseTemplate[requestStatus],
            estimatedValueCd: freightValue === '' || freightValue === 'All' ? null : FreightValueType[freightValue],
          },
          requestType: requestType,
          searchQuery: searchField === '' ? null : searchField,
          fromDate: dateRangeFrom === '' ? null : dateRangeFrom,
          toDate: dateRangeTo === '' ? null : dateRangeTo,
        } as ListSalvageRequestsRqst;
        this.refreshGridSubject.next();
      });
  }

  ngOnInit(): void {}

  public getSalvageRequests(startAt: number, sortOrder: XpoSortExpression[]): Observable<Envelope<ListSalvageRequestsResp>> {
    const sortOrderParam: SortField[] =
      sortOrder.length > 0
        ? [
            {
              sortByFieldName: sortOrder?.[0]?.column,
              sortOrder: sortOrder?.[0]?.direction,
            } as SortField,
          ]
        : [
            {
              sortByFieldName: SalvageRequestApproverGridColumn.Updated,
              sortOrder: 'desc',
            },
          ];

    this.listSalvageRequest.listInfo = {
      ...new ListInfo(),
      startAt: startAt,
      numberOfRows: 50,
      sortFields: sortOrderParam,
    };

    return this.shipmentApiService.listSalvageRequests(this.listSalvageRequest);
  }

  public getSalvageRequest(salvageRequestId: number): void {
    this.showSpinnerSubject.next(true);
    const request: ListSalvageRequestsRqst = {
      ...new ListSalvageRequestsRqst(),
      salvageRequestIds: [salvageRequestId],
      requestType: 'APPROVER_SCREEN',
      listInfo: {
        ...new ListInfo(),
        numberOfRows: 50,
        startAt: 0,
      },
    };
    this.shipmentApiService
      .listSalvageRequests(request)
      .pipe(
        takeUntil(this.unsubscriber.done$),
        finalize(() => {
          this.showSpinnerSubject.next(false);
        })
      )
      .subscribe((resp: Envelope<ListSalvageRequestsResp>) => {
        const response: ListSalvageRequestsResp = {
          ...new ListSalvageRequestsResp(),
          salvageRequests: _get(resp, 'salvageRequests'),
        };
        this.apiResponseSubject.next(response?.salvageRequests?.[0]);
      });
  }

  public setEmptySalvageRequest(): void {
    this.apiResponseSubject.next(new SalvageRequest());
  }

  public updateStatus(formReq: StatusUpdate, isAlreadyExisting: boolean): Observable<Envelope<UpdateSalvageRequestResp>> {
    const request: UpdateSalvageRequestRqst = {
      ...new UpdateSalvageRequestRqst(),
      salvageRequest: {
        ...new SalvageRequest(),
        salvageRequestId: formReq.requestId,
        proNbr: isAlreadyExisting ? formReq.parentPRO : formReq.salvagePro,
        salvageRequestTypeCd: SalvageRequestTypes[formReq.disposition],
        statusCd: SalvageRequestStatusResponseTemplate[formReq.status],
        holdForVendorInd: formReq.holdForVendor,
        abeyanceInd: formReq.abeyanceInd,
      },
      ccEmailAddress: formReq.ccEmail,
      feedbackStatusRequest: formReq.response,
    };
    if (isAlreadyExisting) {
      request.childProNbrs = formReq.childPROs.map(a => a.childPRONumber);
    }
    if (request.salvageRequest.statusCd === 'RequestForPhotos' || request.salvageRequest.statusCd === 'RequestForFeedback') {
      const href = window.location.origin;
      const linkToRequest: string = ' <a href="' + href + '/salvage/' + request.salvageRequest.salvageRequestId + '">' + href + '/salvage/' + request.salvageRequest.salvageRequestId + '</a>';
      request.feedbackStatusRequest = request.feedbackStatusRequest + linkToRequest;
    }
    return this.shipmentApiService.updateSalvageRequest(request);
  }

  public showSuccessSnackBar(message: string) {
    this.notificationService.showSnackBarMessage(message, {
      durationInMillis: 3000,
      status: 'SUCCESS',
    });
  }

  public showErrorSnackBar(message: string) {
    this.notificationService.showSnackBarMessage(message, {
      durationInMillis: 3000,
      status: 'ERROR',
    });
  }

  public getDmsToken(): Observable<RetrieveDmsAuthTokenResp> {
    const retrieveDmsAuthTokenRqst = new RetrieveDmsAuthTokenRqst();
    return this.dmsService.retrieveDmsAuthToken(retrieveDmsAuthTokenRqst);
  }

  public getDmsImage(path, httpParams) {
    return this.dmsService.getArchivedDocument(path, { rendition: '' }, {}, httpParams);
  }
}
