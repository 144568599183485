export enum RouterUriComponents {
  DASHBOARD = 'dashboard',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  IMAGE_UPLOAD_PAGE = 'image-upload',
  IMAGE_SEARCH_PAGE = 'image-search',
  OVERAGE = 'overage',
  SALVAGE = 'salvage',
}
export enum RouterParams {
  FILE_UPLOAD_EVENT = 'file-upload-event',
}
