
<div class="app-header" fxLayout="column" fxLayoutAlign="none stretch">
  <xpo-utils-region-banner [showRegion]="true"></xpo-utils-region-banner>
  <div fxLayout="column" fxLayoutAlign="center center" class="headerStyle">
    <div class="app-header-content" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="app-header-content-left" fxLayout="row" fxLayoutAlign="start center">
        <div class="app-header-content-left__app-title">Edge</div>
        <div class="app-header-content-left__app-sub-title">Overage Photos</div>
      </div>
      <div class="app-header-content-right" fxLayout="row">
        <div class="app-header-content-right-feedback">
          <button
            mat-button
            arial-label="Feedback"
            tabindex="-1"
            *ngIf="feedbackService.feedbackConfig$ | async"
            (click)="openFeedbackDialog()">
            <span class="app-header-content-right-feedback-text">Feedback</span>
            <mat-icon class="app-header-content-right-feedback-icon">feedback</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="app-header-toolbar" fxLayout="row" fxLayoutAlign="center center"></div>
  </div>
</div>
