export class EnumUtil {
  public static toArray(enumValue) {
    const array = [];
    for (const key in enumValue) {
      if (enumValue.hasOwnProperty(key)) {
        array.push(enumValue[key]);
      }
    }
    return array;
  }
}
