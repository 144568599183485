<mat-card class="searchCard">
  <form [formGroup]="imageSearchFormGroup" autocomplete="off">
    <div class="upperForm">
      <mat-form-field appearance="outline" class="searchTextBar">
        <mat-label>Search Description, PRO#, SIC, Other</mat-label>
        <input matInput [autofocus]="true" autofocus (keydown)="searchImage($event, 1)" [formControlName]="ImageSearchFormNames.SearchKey" placeholder='"and" "or" can be used in your search' />
        <div class="absoluteSuffix">
          <mat-icon (click)="searchImage($event, 1)">search</mat-icon>
          <button mat-button type="button" *ngIf="!openAdvancedSearch" class="advancedSearchBtn" (click)="enableAdvancedSearch()">ADVANCED</button>
          <button type="reset" *ngIf="openAdvancedSearch" class="advancedSearchBtn" mat-button color="primary" (click)="cancelAdvancedSearch()">CANCEL</button>
        </div>
      </mat-form-field>

      <mat-form-field class="dateRangePicker">
        <input matInput [matDatepicker]="picker1" placeholder="Archived From" [formControlName]="ImageSearchFormNames.FromDate" />
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
        <mat-error *ngIf="hasError('required', imageSearchFormGroup, ImageSearchFormNames.FromDate)">From Date is required</mat-error>
        <mat-error *ngIf="hasError('xpoMaxDate', imageSearchFormGroup, ImageSearchFormNames.FromDate)">Date cannot be future date</mat-error>
        <mat-error *ngIf="hasError('moreThanToDate', imageSearchFormGroup, ImageSearchFormNames.FromDate)">From Date cannot be after To Date</mat-error>
      </mat-form-field>
      <mat-form-field class="dateRangePicker">
        <input matInput [matDatepicker]="picker2" placeholder="Archived To" [formControlName]="ImageSearchFormNames.ToDate" />
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <mat-error *ngIf="hasError('required', imageSearchFormGroup, ImageSearchFormNames.ToDate)">From Date is required </mat-error>
        <mat-error *ngIf="hasError('xpoMaxDate', imageSearchFormGroup, ImageSearchFormNames.ToDate)">Date cannot be future date</mat-error>
      </mat-form-field>

      <mat-divider [vertical]="true"></mat-divider>
    </div>
    <mat-divider *ngIf="openAdvancedSearch"></mat-divider>
    <div class="bottomForm">
      <div *ngIf="openAdvancedSearch" class="advancedSearchOptions">
        <div class="block-1">
          <mat-form-field class="searchFields">
            <mat-label>PRO #</mat-label>
            <input matInput placeholder="PRO #" [formControlName]="ImageSearchFormNames.ProNbr" />
          </mat-form-field>
          <mat-form-field class="searchFields">
            <mat-label>SIC Code</mat-label>
            <input matInput placeholder="SIC Code" [formControlName]="ImageSearchFormNames.ReportingSicCd" oninput="this.value = this.value.toUpperCase()" />
          </mat-form-field>
          <mat-form-field class="searchFields">
            <mat-label>Package Code</mat-label>
            <input matInput placeholder="Package Code" [formControlName]="ImageSearchFormNames.PackageCd" />
          </mat-form-field>
          <mat-form-field class="searchFields">
            <mat-label>Quantity</mat-label>
            <input matInput placeholder="Quantity" [formControlName]="ImageSearchFormNames.PiecesCount" />
          </mat-form-field>
        </div>
        <div class="block-2">
          <mat-form-field class="searchFields">
            <mat-label>Brand</mat-label>
            <input matInput placeholder="Brand" [formControlName]="ImageSearchFormNames.Brand" />
          </mat-form-field>
          <mat-form-field class="searchFields">
            <mat-label>Reference #</mat-label>
            <input matInput placeholder="Reference #" [formControlName]="ImageSearchFormNames.ReferenceNbr" />
          </mat-form-field>
          <mat-form-field class="searchFields">
            <mat-label>Color</mat-label>
            <input matInput placeholder="Color" [formControlName]="ImageSearchFormNames.Color" />
          </mat-form-field>
          <mat-form-field class="searchFields">
            <mat-label>Length</mat-label>
            <input matInput appDecimalNumber placeholder="Length" [formControlName]="ImageSearchFormNames.LengthNbr" />
          </mat-form-field>
          <mat-form-field class="searchFields">
            <mat-label>Width</mat-label>
            <input matInput appDecimalNumber placeholder="Width" [formControlName]="ImageSearchFormNames.WidthNbr" />
          </mat-form-field>
          <mat-form-field class="searchFields">
            <mat-label>Height</mat-label>
            <input matInput appDecimalNumber placeholder="Height" [formControlName]="ImageSearchFormNames.HeightNbr" />
          </mat-form-field>
          <mat-form-field class="searchFields">
            <mat-label>Weight</mat-label>
            <input matInput appDecimalNumber placeholder="Weight" [formControlName]="ImageSearchFormNames.WeightLbs" />
            <mat-hint class="hint">Lbs</mat-hint>
          </mat-form-field>
        </div>
        <div class="block-3">
          <mat-form-field class="searchFields">
            <mat-label>Product Description</mat-label>
            <input matInput placeholder="Product Description" [formControlName]="ImageSearchFormNames.Description" />
          </mat-form-field>
        </div>
        <div class="actionButtons">
          <div class="actions">
            <button class="searchButton" mat-raised-button color="primary" (click)="runSearch()">
              <mat-icon class="icon">search</mat-icon>
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <mat-divider></mat-divider>
  <div class="upload-photo">
    <!--<label for="">Select Images To Upload</label>-->
    <button class="uploadButton" mat-button id="uploadImage" color="primary" (click)="imageInput.click()">
      <mat-icon matPrefix class="icon">publish</mat-icon>
      Upload Images
    </button>
  </div>
  <input #imageInput hidden label="Image" type="file" accept="image/*" (change)="uploadImage($event)" multiple="true" ngModel (click)="imageInput.value = null" />
</mat-card>
<div *ngIf="searchRan && searchResults.length == 0" class="noresults">No Results Found</div>
<div class="resultsBar">
  <mat-card class="resultsCard" [hidden]="!searchRan">
    <div class="searchHeader">
      <div class="searchResult">Search Results</div>
      <app-core-paginator class="paginator" #paginator></app-core-paginator>
      <button
        mat-flat-button
        xpoLargeButton
        xpoButton
        color="primary"
        [disabled]="isSalvageButtonDisabled$ | async"
        (click)="openSalvageForm()"
        [hidden]="!(isSalvageButtonVisible$ | async) || !(feature$ | async)"
      >
        New Salvage Request
      </button>
    </div>

    <mat-tab-group *ngIf="searchRan && searchResults.length > 0" [(selectedIndex)]="activeTabIndex" (selectedTabChange)="tabChanged($event)" class="tabGrp">
      <mat-tab [label]="allLabel" *ngIf="searchRan && searchResults.length > 0">
        <div class="wrapper">
          <div *ngFor="let result of searchResults">
            <div class="box">
              <img class="thmbnail" [attr.src]="result.thumbnailImage" (click)="openDetailsView($event, result)" alt="IMAGE DATA CORRUPT." onerror="this.onerror=null" />
              <div class="detail">
                <span class="title"> PRO #:</span> <span class="data"> {{ result.proNbr }}</span>
              </div>
              <div class="detail">
                <span class="title">SIC:</span> <span class="data"> {{ result.reportingSicCd }}</span>
              </div>
              <div class="detail">
                <span class="title">Archived:</span> <span class="data"> {{ result.createdTimestamp }}</span>
              </div>
              <div class="detail">
                <span class="title">Salvage Id:</span> <span class="data">  {{ result?.salvageRequestId ? result.salvageRequestId : 'None' }} </span>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="unMatchedLabel" *ngIf="searchRan && unMatchedResults.length > 0">
        <div class="wrapper">
          <div *ngFor="let result of unMatchedResults; let i = index">
            <mat-checkbox labelPosition="before" (change)="imageSelected(i)" [(ngModel)]="result.selected" [disabled]="(result?.salvageRequestId || (!(isSalvageButtonVisible$ | async) || !(feature$ | async)))">
              <div class="box">
                <img class="thmbnail" [attr.src]="result.thumbnailImage" (click)="openDetailsView($event, result)" alt="IMAGE DATA CORRUPT." onerror="this.onerror=null" />
                <div class="detail">
                  <span class="title"> PRO #:</span> <span class="data"> {{ result.proNbr }}</span>
                </div>
                <div class="detail">
                  <span class="title">SIC:</span> <span class="data"> {{ result.reportingSicCd }}</span>
                </div>
                <div class="detail">
                  <span class="title">Archived:</span> <span class="data"> {{ result.createdTimestamp }}</span>
                </div>
                <div class="detail">
                  <span class="title">Salvage Id:</span> <span class="data">  {{ result?.salvageRequestId ? result.salvageRequestId : 'None' }} </span>
                </div>
              </div>
            </mat-checkbox>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="partialMatchedLabel" *ngIf="searchRan && partialMatchedResults.length > 0">
        <div class="wrapper">
          <div *ngFor="let result of partialMatchedResults">
            <div class="box">
              <img class="thmbnail" [attr.src]="result.thumbnailImage" (click)="openDetailsView($event, result)" alt="IMAGE DATA CORRUPT." onerror="this.onerror=null" />
              <div class="detail">
                <span class="title"> PRO #:</span> <span class="data"> {{ result.proNbr }}</span>
              </div>
              <div class="detail">
                <span class="title">SIC:</span> <span class="data"> {{ result.reportingSicCd }}</span>
              </div>
              <div class="detail">
                <span class="title">Archived:</span> <span class="data"> {{ result.createdTimestamp }}</span>
              </div>
              <div class="detail">
                <span class="title">Salvage Id:</span> <span class="data">  {{ result?.salvageRequestId ? result.salvageRequestId : 'None' }} </span>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="matchedLabel" *ngIf="searchRan && matchedResults.length > 0">
        <div class="wrapper">
          <div *ngFor="let result of matchedResults">
            <div class="box">
              <img class="thmbnail" [attr.src]="result.thumbnailImage" (click)="openDetailsView($event, result)" alt="IMAGE DATA CORRUPT." onerror="this.onerror=null" />
              <div class="detail">
                <span class="title"> PRO #:</span> <span class="data"> {{ result.proNbr }}</span>
              </div>
              <div class="detail">
                <span class="title">SIC:</span> <span class="data"> {{ result.reportingSicCd }}</span>
              </div>
              <div class="detail">
                <span class="title">Archived:</span> <span class="data"> {{ result.createdTimestamp }}</span>
              </div>
              <div class="detail">
                <span class="title">Salvage Id:</span> <span class="data">  {{ result?.salvageRequestId ? result.salvageRequestId : 'None' }} </span>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
  <mat-card [hidden]="isSalvageButtonVisible$ | async" class="salvageForm">
    <app-salvage-request></app-salvage-request>
  </mat-card>
</div>
