import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, take, takeUntil } from 'rxjs/operators';
import { SalvageFormSuccessDialogComponent } from '../../../dialogs/salvage-form-success-dialog/salvage-form-success-dialog.component';
import { SalvageFormNames } from '../../../enums/salvage-form-names.enum';
import { QualityCdEnum } from '../../../enums/quality-cd.enum';
import { SalvageRequestService } from '../../../services/salvage-request-service/salvage-request.service';
import { toString as _toString, startsWith as _startsWith } from 'lodash';
import { MatDrawer } from '@angular/material/sidenav';
import { CommodityPackageCdHelper, Envelope, SalvageRequestTypeCd } from '@xpo-ltl/sdk-common';
import { get as _get } from 'lodash';
import { CreateSalvageRequestResp, OsdImage } from '@xpo-ltl-2.0/sdk-shipment';
import { CommodityPackageCdPipe } from '../../../pipes/commodity-package-cd.pipe';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { TAB } from '@angular/cdk/keycodes';
import { NotificationService } from '@xpo-ltl/data-api';
import { GetServiceCenterDetailsBySicPath, LocationApiService } from '@xpo-ltl-2.0/sdk-location';

@Component({
  selector: 'app-salvage-request',
  templateUrl: './salvage-request.component.html',
  styleUrls: ['./salvage-request.component.scss'],
})
export class SalvageRequestComponent implements OnInit, OnDestroy {
  @Output() salvageFormCloseEvent = new EventEmitter<void>();
  public salvageForm: FormGroup;
  readonly SalvageFormNames = SalvageFormNames;
  readonly QualityCdEnum = QualityCdEnum;
  readonly spHandlingTooltipPosition = 'after';
  readonly specialHandlingTooltip: string = 'Are the contents of this incident in any way qualified as DANGEROUS, HAZARDOUS, Rx, LICENSED or REGULATED?';
  selectedPROList = [];
  totalPiecesCount: Number;
  numberArrayValidator = Validators.pattern('^[0-9, ]*$');

  public requestTypes = ['Common Disposal', 'Company Use', 'Canada Salvage', 'Regulated Disposal', 'United State Salvage'];
  public brandList = [];
  public packagingTypes = ['P1', 'P2', 'P3', 'P4', 'P5'];
  public qualityTypes = ['0', '1', '2', '3', '4', '5'];
  public valueTypes = ['Trash', '$1-$149', '$150-$499', '$500-$999', '$1,000-$4,999', '$5,000 or more'];
  public defaultErrorMessage = 'Error while Creating Salvage Request';
  public packageCds = [];

  isAlreadyExistingSubject = new BehaviorSubject<boolean>(false);
  isAlreadyExisting$ = this.isAlreadyExistingSubject.asObservable();

  filteredQualityofFreight$: Observable<String[]>;
  filteredOptionsBrand$: Observable<String[]>;
  filteredRequestType$: Observable<String[]>;
  filteredPackagingType$: Observable<String[]>;
  public filteredOptionsPackageCd: Observable<string[]>;
  public packagingListOptions: string[] = CommodityPackageCdHelper.values()
    .sort()
    .map(v => CommodityPackageCdPipe.prototype.transform(v));

  @ViewChild('pkgCd') matAutocompletePkgCd: MatAutocomplete;
  @ViewChild('quaFrCd') matAutocompleteQuaFrCd: MatAutocomplete;

  private unsubscriber: Unsubscriber = new Unsubscriber();
  public unsubscribe: Subject<void> = new Subject();
  filteredQualityofFreightCd: Observable<string[]>;

  constructor(
    private salvageRequestService: SalvageRequestService,
    private locationApiService: LocationApiService,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private fb: FormBuilder
  ) {}

  private decimalNumberPattern = '^[0-9]+(.[0-9]+)?$';

  ngOnInit(): void {
    this.salvageForm = new FormGroup({
      [SalvageFormNames.RequestName]: new FormControl('', Validators.required),
      [SalvageFormNames.RequestType]: new FormControl('', Validators.required),
      [SalvageFormNames.SIC]: new FormControl('', Validators.required),
      [SalvageFormNames.FreightDescription]: new FormControl({ value: '', disabled: true }, Validators.required),
      [SalvageFormNames.Ref]: new FormControl({ value: '', disabled: true }),
      [SalvageFormNames.Brand]: new FormControl('', Validators.required),
      [SalvageFormNames.PackagingType]: new FormControl('', Validators.required),
      [SalvageFormNames.SpecialHandling]: new FormControl('', Validators.required),
      [SalvageFormNames.TotalWeight]: new FormControl('', [Validators.maxLength(11), Validators.required]),
      [SalvageFormNames.LengthNbr]: new FormControl('', [Validators.maxLength(7), Validators.pattern(this.decimalNumberPattern)]),
      [SalvageFormNames.WidthNbr]: new FormControl('', [Validators.maxLength(7), Validators.pattern(this.decimalNumberPattern)]),
      [SalvageFormNames.HeightNbr]: new FormControl('', [Validators.maxLength(7), Validators.pattern(this.decimalNumberPattern)]),
      [SalvageFormNames.CubeNbr]: new FormControl('', [Validators.maxLength(7), Validators.pattern(this.decimalNumberPattern)]),
      [SalvageFormNames.QualityofFreight]: new FormControl('', Validators.required),
      [SalvageFormNames.ValueofFreight]: new FormControl('', Validators.required),
      [SalvageFormNames.Comments]: new FormControl('', Validators.maxLength(4000)),
      [SalvageFormNames.VisibleInd]: new FormControl(null, Validators.required),
      [SalvageFormNames.SearchInd]: new FormControl(null, Validators.required),
      [SalvageFormNames.DamageInformationInd]: new FormControl(false),
      [SalvageFormNames.ParentPRO]: new FormControl(''),
      [SalvageFormNames.ChildPROs]: this.fb.array([]),
    });

    this.watchSic(this.salvageForm.get(SalvageFormNames.SIC));

    this.setBrandList();
    this.setOptionsFilter();

    this.salvageRequestService.salvageButtonVisibility$.pipe(takeUntil(this.unsubscriber.done$)).subscribe(resp => {
      this.salvageRequestService.salvagePROList$.pipe(takeUntil(this.unsubscriber.done$)).subscribe(response => {
        this.autoPopulateForm(response);
      });
    });

    this.handleDimensions();
  }

  getReference(osdImage: OsdImage[]): string {
    if (!osdImage) {
      return '';
    }
    let reference = osdImage
      .map(imageHeader => imageHeader.referenceNbr)
      .filter(referenceNbr => !!referenceNbr)
      .join('; ');
    return reference;
  }

  get childpros(): FormArray {
    return this.salvageForm?.get(SalvageFormNames.ChildPROs) as FormArray;
  }

  addChildPRO() {
    const child = this.fb.group({
      childPRONumber: [''],
    });
    this.childpros.push(child);
  }

  removeChildPRO() {
    this.childpros.removeAt(this.childpros.length - 1);
  }

  dimensionsChecker() {
    const currentLengthValue = this.salvageForm.get(SalvageFormNames.LengthNbr).value;
    const currentWidthValue = this.salvageForm.get(SalvageFormNames.WidthNbr).value;
    const currentHeightValue = this.salvageForm.get(SalvageFormNames.HeightNbr).value;

    if (currentLengthValue?.toString()?.length > 0 || currentWidthValue?.toString()?.length > 0 || currentHeightValue?.toString()?.length > 0) {
      this.salvageForm.get(SalvageFormNames.CubeNbr).disable();
    }
    if (currentLengthValue?.toString()?.length === 0 && currentWidthValue?.toString()?.length === 0 && currentHeightValue?.toString()?.length === 0) {
      if (this.salvageForm.get(SalvageFormNames.CubeNbr)?.disabled) {
        this.salvageForm.get(SalvageFormNames.CubeNbr).enable();
      }
    }
    if (!currentLengthValue && !currentWidthValue && !currentHeightValue) {
      if (this.salvageForm.get(SalvageFormNames.CubeNbr)?.disabled) {
        this.salvageForm.get(SalvageFormNames.CubeNbr).enable();
      }
    }
  }

  private handleDimensions() {
    this.salvageForm.get(SalvageFormNames.LengthNbr).valueChanges.subscribe(value => {
      this.dimensionsChecker();
    });

    this.salvageForm.get(SalvageFormNames.WidthNbr).valueChanges.subscribe(value => {
      this.dimensionsChecker();
    });

    this.salvageForm.get(SalvageFormNames.HeightNbr).valueChanges.subscribe(value => {
      this.dimensionsChecker();
    });

    this.salvageForm.get(SalvageFormNames.CubeNbr).valueChanges.subscribe(curentCubicFtValue => {
      if (curentCubicFtValue?.toString()?.length > 0) {
        this.salvageForm.get(SalvageFormNames.LengthNbr)?.enabled ? this.salvageForm.get(this.SalvageFormNames.LengthNbr).disable() : null;
        this.salvageForm.get(SalvageFormNames.WidthNbr)?.enabled ? this.salvageForm.get(this.SalvageFormNames.WidthNbr).disable() : null;
        this.salvageForm.get(SalvageFormNames.HeightNbr)?.enabled ? this.salvageForm.get(this.SalvageFormNames.HeightNbr).disable() : null;
      }
      if (curentCubicFtValue?.toString()?.length === 0 || !curentCubicFtValue) {
        this.salvageForm.get(SalvageFormNames.LengthNbr)?.disabled ? this.salvageForm.get(this.SalvageFormNames.LengthNbr)?.enable() : null;
        this.salvageForm.get(SalvageFormNames.WidthNbr)?.disabled ? this.salvageForm.get(this.SalvageFormNames.WidthNbr).enable() : null;
        this.salvageForm.get(SalvageFormNames.HeightNbr)?.disabled ? this.salvageForm.get(this.SalvageFormNames.HeightNbr).enable() : null;
      }
    });
  }

  getControl(controlName: string): FormControl {
    return this.salvageForm.get(controlName) as FormControl;
  }

  private autoPopulateForm(resp) {
    this.selectedPROList = resp.map(list => list.proNbr);
    this.salvageForm.controls[SalvageFormNames.FreightDescription].setValue(resp.map(list => list.description).join(', '));
    this.salvageForm.controls[SalvageFormNames.Ref].setValue(resp.map(list => list.referenceNbr).join(', '));
    this.totalPiecesCount = 0;
    resp.forEach(element => {
      this.totalPiecesCount = this.totalPiecesCount + element.piecesCount;
    });
    this.salvageForm.get(SalvageFormNames.SIC).setValue(resp[0]?.reportingSicCd);

    let totalWeight = 0;

    resp.forEach(item => {
      totalWeight += item.weightLbs ? item.weightLbs : 0;
    });

    if (totalWeight) {
      this.salvageForm.get(SalvageFormNames.TotalWeight).setValue(totalWeight);
    }
  }

  private setBrandList(): void {
    this.salvageRequestService.brandList$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((resp: string[]) => {
      this.brandList = resp;
      this.salvageForm.get(SalvageFormNames.Brand).setValue('');
    });
  }

  setOptionsFilter() {
    this.filteredRequestType$ = this.salvageForm.get(SalvageFormNames.RequestType).valueChanges.pipe(
      takeUntil(this.unsubscriber.done$),
      startWith(''),
      map(value => {
        const filterValue = _toString(value).toLowerCase();
        return this.requestTypes.filter(reqType => _startsWith(reqType.toLowerCase(), filterValue));
      })
    );

    this.filteredOptionsBrand$ = this.salvageForm.get(SalvageFormNames.Brand).valueChanges.pipe(
      takeUntil(this.unsubscriber.done$),
      startWith(''),
      map(value => {
        const filterValue = _toString(value).toLowerCase();
        return this.brandList.filter(brand => _startsWith(brand.toLowerCase(), filterValue)).slice(0, 10);
      })
    );

    this.filteredOptionsPackageCd = this.configurePckgTypeFilter(this.packagingListOptions);
    this.filteredQualityofFreightCd = this.configureQuaFrTypeFilter(this.qualityTypes);
  }

  public configurePckgTypeFilter(options) {
    const filteredOptions = this.salvageForm.get(SalvageFormNames.PackagingType).valueChanges.pipe(
      startWith(''),
      map(value => this._filterPkgCd(value, options))
    );
    return filteredOptions;
  }

  public configureQuaFrTypeFilter(options) {
    const filteredOptions = this.salvageForm.get(SalvageFormNames.QualityofFreight).valueChanges.pipe(
      startWith(''),
      map(value => {
        return this._filterQuaFreightCd(value, options);
      })
    );
    return filteredOptions;
  }

  private _filterPkgCd(value: string, options: string[]): string[] {
    const filterValue = value
      ?.toUpperCase()
      .substring(value.lastIndexOf(',') + 1)
      .trim();

    return options.filter(option => {
      if (filterValue?.length === 3) {
        const keyValue = option
          .toUpperCase()
          .split('-')[0]
          .trim();
        return filterValue === keyValue;
      } else if (filterValue?.length < 3) {
        const values = option.toUpperCase().split('-');
        return values[0].trim().startsWith(filterValue) || values[1].trim().startsWith(filterValue);
      }
      return option.toUpperCase().includes(filterValue);
    });
  }

  private _filterQuaFreightCd(value: string, options: string[]): string[] {
    const filterValue = value?.toUpperCase().trim();

    return options.filter((option: String) => {
      const values = _toString(QualityCdEnum[+option])
        .toUpperCase()
        .split('-');
      return _toString(values[1])
        .trim()
        .startsWith(filterValue);
    });
  }

  onQualityCdKeydown(event): void {
    if (event.keyCode === TAB) {
      const activeOptions = this.matAutocompleteQuaFrCd.options.filter(item => item.active);
      if (activeOptions.length > 0) {
        this.salvageForm.get(SalvageFormNames.QualityofFreight).setValue(activeOptions[0].value);
      }
    }
  }

  onPackageCdKeydown(event): void {
    if (event.keyCode === TAB) {
      const activeOptions = this.matAutocompletePkgCd.options.filter(item => item.active);
      if (activeOptions.length > 0) {
        this.salvageForm.get(SalvageFormNames.PackagingType).setValue(activeOptions[0].value);
      }
    }
  }

  salvageFormSubmit(): void {
    if (this.salvageForm.invalid) {
      this.salvageForm.markAllAsTouched();
      return;
    } else {
      const request = this.salvageForm.getRawValue();
      // removing ref prop as it doesn't exist in backend now
      delete request.ref;
      this.salvageRequestService
        .createSalvageRequest(request, this.isAlreadyExistingSubject.value)
        .pipe(take(1))
        .subscribe(
          (resp: Envelope<CreateSalvageRequestResp>) => {
            this.salvageForm.reset();
            this.salvageRequestService.updateSalvageButtonVisibility(true);
            const dialogRef = this.dialog.open(SalvageFormSuccessDialogComponent, {
              data: _get(resp, 'salvageRequest'),
            });
            dialogRef
              .afterClosed()
              .pipe(take(1))
              .subscribe(val => {
                if (val) {
                  this.salvageRequestService.overageImageSearchSubject.next();
                }
              });
          },
          error => {
            this.notificationService.showSnackBarMessage(error?.error?.moreInfo?.[0]?.message ?? this.defaultErrorMessage, { durationInMillis: 3000, status: 'ERROR' });
            if (error?.error?.moreInfo[0]?.message === 'The PRO is already in use') {
              this.isAlreadyExistingSubject.next(true);
              if (this.childpros.length > 0) {
                for (let i = 0; i < this.childpros.length; i++) {
                  this.removeChildPRO();
                }
              }
              this.addChildPRO();
              this.salvageForm?.get(SalvageFormNames.ParentPRO).setValidators([Validators.required]);
              this.salvageForm?.get(SalvageFormNames.ChildPROs).setValidators([Validators.required]);
              this.salvageForm.markAllAsTouched();
            }
          }
        );
    }
  }

  salvageFormCancel() {
    this.salvageForm.reset();
    this.salvageRequestService.updateSalvageButtonVisibility(true);
    this.isAlreadyExistingSubject.next(false);
    this.salvageForm?.get(SalvageFormNames.ParentPRO).clearValidators();
    this.salvageForm?.get(SalvageFormNames.ChildPROs).clearValidators();
  }

  deletePRO(proNbr) {
    this.salvageRequestService.updatelastDeletedPRO(proNbr);
  }

  public hasError(error: string, formGrp?: FormGroup, field?: SalvageFormNames): boolean {
    if (formGrp && formGrp.hasError(error)) {
      return formGrp.hasError(error) && formGrp.touched;
    } else if (formGrp && field) {
      return formGrp.get(field).hasError(error) && formGrp.get(field).touched;
    }
  }

  private validateSicCode(sicCode: string) {
    const sicrqst = new GetServiceCenterDetailsBySicPath();
    sicrqst.sicCd = sicCode.trim().toUpperCase();
    this.locationApiService
      .getServiceCenterDetailsBySic(sicrqst, { loadingOverlayEnabled: false, toastOnError: false })
      .toPromise()
      .then(
        response => {
          if (response && response.sicCd === sicCode && response.sicCd !== '000') {
            this.salvageForm.get(SalvageFormNames.SIC).setErrors(null);
          } else {
            this.salvageForm.get(SalvageFormNames.SIC).setErrors({ invalidSicCode: true });
          }
        },
        () => {
          // this.notificationService.showSnackBarMessage('SIC Cannot be validated since Location service is down', { durationInMillis: 3000, status: 'WARNING' });
          this.salvageForm.get(SalvageFormNames.SIC).setErrors({ invalidSicCode: true });
        }
      );
  }

  private watchSic(watchControl: AbstractControl) {
    watchControl.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.unsubscribe), debounceTime(100)).subscribe(value => {
      if (value && value.length === 3) {
        this.validateSicCode(value);
      }
    });
  }

  displayFn(type): string | null {
    return type ? type : null;
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
}
