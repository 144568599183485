import {
  AfterContentInit,
  Component,
  ComponentFactoryResolver,
  Inject,
  Type,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogTemplateInjectorDirective } from '../dialog-template-injector.directive';
import { InjectedDialogComponent } from '../../interfaces/injected-dialog-component.interface';
import { AlertDialogData } from '../../interfaces/alert-dialog-data.interface';

@Component({
  selector: 'app-alert-wrapper',
  templateUrl: './alert-wrapper.component.html',
  styleUrls: ['./alert-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertWrapperComponent implements AfterContentInit {
  @ViewChild(DialogTemplateInjectorDirective)
  messageContent: DialogTemplateInjectorDirective;

  constructor(
    private dialogRef: MatDialogRef<AlertWrapperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngAfterContentInit() {
    if (this.data.contentComponent) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
        this.data.contentComponent,
      );
      const componentRef = this.messageContent.viewContainerRef.createComponent(
        componentFactory,
      );
      (<InjectedDialogComponent>componentRef.instance).dataObservable = this.data.injectedData;
    }
  }

  handleCloseClick(): void {
    this.dialogRef.close();
  }
}
