import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { RoleCheckGuard } from './guards/role-check-guard';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { RouterParams, RouterUriComponents } from './enums/router-uri-components.enum';
import { NotAuthorizedRedirectGuard } from './guards/not-authorized-redirect.guard';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { ImageSearchComponent } from './components/image-search/image-search.component';
import { XpoAuthenticationGuard, XpoAuthorizationGuard } from '@xpo/ngx-auth';
import { ImageLandingComponent } from './components/image-landing/image-landing.component';
import { SalvageComponent } from './components/salvage/salvage.component';

export const RouterDefinitions: Routes = [
  {
    path: RouterUriComponents.NOT_AUTHORIZED_PAGE,
    component: NotAuthorizedComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: RouterUriComponents.DASHBOARD,
    component: ImageSearchComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  {
    // path:  `${RouterUriComponents.IMAGE_UPLOAD_PAGE}/:${RouterParams.FILE_UPLOAD_EVENT}`,
    path: RouterUriComponents.IMAGE_UPLOAD_PAGE,
    component: ImageUploadComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: RouterUriComponents.OVERAGE,
    component: ImageSearchComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: 'salvage/:id',
    loadChildren: () => import('./components/salvage/salvage.module').then(m => m.SalvageModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: RouterUriComponents.SALVAGE,
    loadChildren: () => import('./components/salvage/salvage.module').then(m => m.SalvageModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: '',
    redirectTo: `/${RouterUriComponents.OVERAGE}`,
    pathMatch: 'full',
  },
  { path: '**', redirectTo: RouterUriComponents.OVERAGE, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(RouterDefinitions, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [RoleCheckGuard],
  declarations: [],
})
export class AppRoutingModule {}
