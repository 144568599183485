<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.3/css/all.css" integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/" crossorigin="anonymous" />
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
<div class="detailsPage">
  <input #imagesInput hidden label="Image" type="file" accept="image/*" (change)="processImage($event)" multiple="true" ngModel (click)="imagesInput.value = null" />
  <div class="detailsCard">
    <div class="imageContainer">
      <div>
        <div *ngIf="!updateIndicator" class="imageCount">Photos Uploaded ({{ images.length }})</div>
        <div *ngIf="updateIndicator" class="imageCount">
          Photos Uploaded ({{ galleryImages2.length }}) <button class="closeButton_resp" *ngIf="updateIndicator" [mat-dialog-close]="false"><mat-icon>clear</mat-icon></button>
        </div>
        <button *ngIf="!updateIndicator" mat-button color="primary" id="uploadImages" (click)="imagesInput.click()">Upload More</button>
        <button *ngIf="!updateIndicator" mat-button class="delete" [disabled]="this.selectedImages.length == 0" (click)="deleteImages()"><mat-icon>delete</mat-icon></button>
      </div>
      <div class="spaced"></div>
      <div class="image-enlarge-text">Click the image to enlarge</div>
      <div *ngIf="loading_complete && !updateIndicator"><ngx-gallery [options]="galleryOptions" [images]="galleryImages"> </ngx-gallery></div>

      <div *ngIf="loadingDMSImages && updateIndicator" class="spinner">
        <!--  <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple">
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner> -->
        <mat-spinner><div>Loading DMS Images</div></mat-spinner>
      </div>
      <div *ngIf="updateIndicator && !loadingDMSImages && !editClicked && !analyzeImageOnMatch"><ngx-gallery class="gallery" [options]="galleryOptions2" [images]="galleryImages2"> </ngx-gallery></div>
      <div *ngIf="updateIndicator && !loadingDMSImages && editClicked"><ngx-gallery class="gallery" [options]="galleryOptions2" [images]="galleryImages2"> </ngx-gallery></div>
      <div *ngIf="updateIndicator && !loadingDMSImages && analyzeImageOnMatch"><ngx-gallery class="gallery" [options]="galleryOptions2" [images]="galleryImages2"> </ngx-gallery></div>
      <button
      *ngIf="editClicked && !hideImageAnalyzer"
        mat-stroked-button color="primary"
        (click)="analyzeImages()"
        class="analyzeImages">Analyze Images</button>
        <button
        *ngIf="analyzeImageOnMatch && !hideImageAnalyzer"
          mat-stroked-button color="primary"
          (click)="analyzeImages()"
          class="analyzeImages">Analyze Images</button>
      <div *ngIf="!loading_complete && !updateIndicator" class="progress-indicator">
        <div>Optimizing the Images</div>
        <mat-progress-bar class="analyzeImages" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
    <mat-divider class="seperator" [vertical]="true"></mat-divider>
    <div class="details-container">
      <!--  <div class="info"> -->
      <div class="details-container-header">
        <div class="form-title-hdr">
          Photo Details <button class="closeButton_normal" *ngIf="updateIndicator" (click)="cancelEditing()"><mat-icon>clear</mat-icon></button>
        </div>
      </div>
      <div class="details-container-body">
        <form [formGroup]="imageUploadFormGroup">
          <div class="details-container-body-block-1">
            <mat-form-field appearance="fill" class="custom-form-group-tags">
              <mat-label>Selected Tags</mat-label>
              <textarea #tags matInput placeholder="Selected Tags" [formControlName]="ImageUploadFormNames.SelectedTags"></textarea>
              <mat-hint align="end">{{ tags.value.length }} / 4000</mat-hint>
              <mat-error *ngIf="hasError('maxlength', imageUploadFormGroup, ImageUploadFormNames.SelectedTags)">
                Selected Tags cannot be more than 4000 characters {{ tags.value.length }} / 4000</mat-error
              >
            </mat-form-field>
          </div>
          <div class="details-container-body-block-2">
            <mat-form-field appearance="fill" class="custom-form-group-tags">
              <mat-label>Product Description</mat-label>
              <textarea #desc matInput placeholder="Product Description" [formControlName]="ImageUploadFormNames.Description" [matAutocomplete]="descAuto"></textarea>
              <mat-autocomplete #descAuto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptionsDesc | async" [value]="desc.value?.substring(0, desc.value?.lastIndexOf(',') + 1) + option"> {{ option }} </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.Description)"> Description is Required </mat-error>
              <mat-error *ngIf="hasError('maxlength', imageUploadFormGroup, ImageUploadFormNames.Description)">
                Description cannot be more than 4000 characters {{ desc.value.length }} / 4000</mat-error
              >
              <mat-hint align="end">{{ desc.value.length }} / 4000</mat-hint>
            </mat-form-field>
          </div>
          <div class="details-container-body-block-3">
            <mat-form-field class="customfield">
              <mat-label>PRO #</mat-label>
              <input matInput placeholder="PRO #" [formControlName]="ImageUploadFormNames.ProNbr" />
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.ProNbr)"> PRO Number is Required. </mat-error>
              <mat-error *ngIf="hasError('invalidProFormat', imageUploadFormGroup, ImageUploadFormNames.ProNbr)"> PRO Number is invalid. </mat-error>
            </mat-form-field>
            <mat-form-field class="customfield">
              <mat-label>SIC</mat-label>
              <input matInput placeholder="SIC " [formControlName]="ImageUploadFormNames.ReportingSicCd" oninput="this.value = this.value.toUpperCase()" />
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.ReportingSicCd)"> SIC Code is Required </mat-error>
              <mat-error *ngIf="hasError('pattern', imageUploadFormGroup, ImageUploadFormNames.ReportingSicCd)"> SIC Code format is Invalid </mat-error>
              <mat-error *ngIf="hasError('invalidSicCode', imageUploadFormGroup, ImageUploadFormNames.ReportingSicCd)"> SIC Code is not Usable </mat-error>
            </mat-form-field>
            <mat-form-field class="customfield">
              <mat-label>Packaging Type</mat-label>
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.PackageCd)"> Packaging Code is Required. </mat-error>
              <input matInput placeholder="Packaging Type " [formControlName]="ImageUploadFormNames.PackageCd" [matAutocomplete]="pkgCd" />
              <mat-autocomplete #pkgCd="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptionsPackageCd | async" [value]="option"> {{ option }} </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="customfield">
              <mat-label>Quantity </mat-label>
              <input matInput placeholder="Quantity" [formControlName]="ImageUploadFormNames.PiecesCount" />
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.PiecesCount)"> Quantity is Required. </mat-error>
              <mat-error *ngIf="hasError('pattern', imageUploadFormGroup, ImageUploadFormNames.PiecesCount)"> Quantity needs to be a number. </mat-error>
            </mat-form-field>
            <mat-form-field class="customfield">
              <mat-label>Brand/Manufacturer</mat-label>
              <input matInput #brand placeholder="Brand/Manufacturer" [formControlName]="ImageUploadFormNames.Brand" [matAutocomplete]="auto" />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="brand.value?.substring(0, brand.value?.lastIndexOf(',') + 1) + option"> {{ option }} </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.Brand)"> Brand is Required. </mat-error>
            </mat-form-field>
          </div>
          <div class="details-container-body-block-4">
            <mat-form-field class="custom-form-group-info-refNbrss">
              <mat-label>Reference #'s (Model #, Serial #, UPC #)</mat-label>
              <input #refNbrs matInput placeholder="Reference #'s (Model #, Serial #, UPC #)" [formControlName]="ImageUploadFormNames.ReferenceNbr" [matAutocomplete]="refNbr" />
              <mat-autocomplete #refNbr="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptionsSerialNbr | async" [value]="refNbrs.value?.substring(0, refNbrs.value?.lastIndexOf(',') + 1) + option"> {{ option }} </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="details-container-body-block-5">
            <mat-form-field class="color-input">
              <mat-select placeholder="Color" [formControlName]="ImageUploadFormNames.Color" multiple>
                <mat-option *ngFor="let color of ColorsEnum" [value]="color">{{ color }} <span [ngClass]="colorClass(color)"></span></mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.Color)"> Color is Required. </mat-error>
            </mat-form-field>
            <mat-form-field class="upload-date">
              <mat-label>Upload Date</mat-label>
              <input matInput placeholder="Reference # " [formControlName]="ImageUploadFormNames.UploadDate" />
            </mat-form-field>
            <mat-form-field class="measures">
              <mat-label>Length</mat-label>
              <input matInput appDecimalNumber placeholder="Length" [formControlName]="ImageUploadFormNames.LengthNbr" />
              <mat-hint>Inches</mat-hint>
              <mat-error *ngIf="hasError('pattern', imageUploadFormGroup, ImageUploadFormNames.LengthNbr)"> XXX.XX </mat-error>
            </mat-form-field>
            <mat-form-field class="measures">
              <mat-label>Width/Diam</mat-label>
              <input matInput appDecimalNumber placeholder="Width/Diam" [formControlName]="ImageUploadFormNames.WidthNbr" />
              <mat-hint>Inches</mat-hint>
              <mat-error *ngIf="hasError('pattern', imageUploadFormGroup, ImageUploadFormNames.WidthNbr)"> XXX.XX </mat-error>
            </mat-form-field>
            <mat-form-field class="measures">
              <mat-label>Height</mat-label>
              <input matInput appDecimalNumber placeholder="Height" [formControlName]="ImageUploadFormNames.HeightNbr" />
              <mat-hint>Inches</mat-hint>
              <mat-error *ngIf="hasError('pattern', imageUploadFormGroup, ImageUploadFormNames.HeightNbr)"> XXX.XX </mat-error>
            </mat-form-field>
            <mat-form-field class="measures">
              <mat-label>Weight</mat-label>
              <input matInput appDecimalNumber placeholder="Weight" [formControlName]="ImageUploadFormNames.WeightLbs" />
              <mat-hint>Lbs</mat-hint>
              <mat-error *ngIf="hasError('pattern', imageUploadFormGroup, ImageUploadFormNames.WeightLbs)"> XXXXXX.XX </mat-error>
            </mat-form-field>
          </div>
          <div class="details-container-body-block-6">
            <mat-form-field *ngIf="updateIndicator" class="customfield">
              <mat-label>Original PRO #</mat-label>
              <input matInput placeholder="Original PRO #" [formControlName]="ImageUploadFormNames.OriginProNbr" />
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.OriginProNbr)">Original PRO # required </mat-error>
              <mat-error *ngIf="hasError('invalidProFormat', imageUploadFormGroup, ImageUploadFormNames.OriginProNbr)">Original PRO Number is invalid. </mat-error>
              <mat-error *ngIf="hasError('maxlength', imageUploadFormGroup, ImageUploadFormNames.OriginProNbr)">Cannot accept more than 9 digit PRO. </mat-error>
            </mat-form-field>
          </div>
          <div class="details-container-body-block-7">
            <mat-form-field *ngIf="updateIndicator" appearance="fill" class="customfield">
              <mat-label>Previous Comments</mat-label>
              <textarea class="text" matInput placeholder="Previous Comments" [disabled]="true">{{ previousComments }}</textarea>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="matchClicked || unMatchClicked || partialMatchClicked || editClicked" class="customfield">
              <mat-label>Comments<span *ngIf="unMatchClicked || partialMatchClicked || imageUploadFormGroup?.get(this.ImageUploadFormNames.OriginProNbr)?.value === '' " class="mandatory-field">* </span></mat-label>
              <textarea class="text" matInput placeholder="Comments" [formControlName]="ImageUploadFormNames.Comment"></textarea>
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.Comment)">Comments are required </mat-error>
            </mat-form-field>
          </div>
        </form>
      </div>
      <!--</div>-->
      <div class="details-container-footer">
        <div class="action-buttons" *ngIf="!updateIndicator">
          <button mat-button color="primary" type="button" (click)="cancel()">Cancel</button> <button mat-raised-button color="primary" (click)="saveImage()">Save Photos</button>
        </div>
        <div class="dialog-action-buttons" *ngIf="updateIndicator">
          <!-- Match buttons -->
          <button
            class="actionButton"
            mat-raised-button
            color="primary"
            *ngIf="(imagestatus == ImageStatus.UnMatched && !matchClicked) || (imagestatus == ImageStatus.PartialMatched && !partialMatchClicked && !matchClicked)"
            [disabled]="editClicked"
            (click)="updateMatchClicked()"
          >
            Match
          </button>
          <button
            class="actionButton"
            mat-raised-button
            color="primary"
            *ngIf="imagestatus == ImageStatus.Matched && !unMatchClicked && !partialMatchClicked && !isTenDigitPro"
            [disabled]="editClicked"
            (click)="updatePartialMatchClicked()"
          >
            Partial Match
          </button>
          <button
            class="actionButton"
            mat-raised-button
            color="primary"
            *ngIf="imagestatus == ImageStatus.Matched && !unMatchClicked && !partialMatchClicked"
            [disabled]="editClicked"
            (click)="updateUnMatchedClicked()"
          >
            UnMatch
          </button>
          <button class="actionButton" mat-raised-button color="primary" *ngIf="matchClicked" (click)="matchImage()">Confirm Match</button>
          <button class="actionButton" mat-raised-button color="primary" *ngIf="unMatchClicked" (click)="unMatchImage()">Confirm UnMatch</button>
          <button class="actionButton" mat-raised-button color="primary" *ngIf="partialMatchClicked" (click)="partialMatchImage()">Confirm Partial Match</button>
          <!-- /Match buttons -->
          <!-- Edit buttons -->
          <button class="actionButton" mat-button color="primary" *ngIf="!editClicked && !matchClicked && !unMatchClicked && !partialMatchClicked" (click)="enableEditImage()">Edit</button>
          <button class="actionButton" mat-raised-button color="primary" *ngIf="editClicked" (click)="saveAfterEdit()">Save</button>
          <!-- /Edit buttons -->
          <button class="actionButton" mat-button color="primary" type="button" (click)="cancelEditing()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
