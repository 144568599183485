import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { trim as _trim, get as _get } from 'lodash';

import { NotificationService } from '@xpo-ltl/data-api';
import {
  ShipmentApiService,
  ListBrandsForSalvageQuery,
  ListBrandsForSalvageResp,
  ListSalvageRequestsRqst,
  OsdImage,
  SearchOsdImagesRqst,
  OsdImageSearchRecord,
  OsdImageSearchFilter,
  OsdImageSearchFilterOr,
  OsdImageSearchFilterAnd,
  // ListSalvageRequestsQuery,
} from '@xpo-ltl-2.0/sdk-shipment';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { ConditioningService, FormatValidationService } from '@xpo-ltl/common-services';
import { ImageSearchFormNames } from '../../enums/image-search-form-names.enum';
import { RouterUriComponents } from '../../enums/router-uri-components.enum';
import { ImageUploadService } from '../../services/image-upload-service/image-upload.service';
import { touchAllControls } from '../../utils/touchAllControls';
import { SearchResultsTabs } from '../../enums/search-results-tabs.enum';
import { ProNumberFormatterPipe } from '../../pipes/pro-number-formatter.pipe';
import { ImageDetailsComponent } from '../image-details/image-details.component';
import { ImageUpdateActions } from '../../enums/image-update-actions.enum';
import { xpoMaxDateValidator } from '../../core/validators/max-date.validators';
import { PaginatorComponent } from '../../core/paginator/paginator.component';
import { Envelope, OverageApprovalStatusCd, XrtAttributeFilter, XrtSortExpression } from '@xpo-ltl/sdk-common';
import { SalvageFormNames } from '../../enums/salvage-form-names.enum';
import { SalvageRequestService } from '../../services/salvage-request-service/salvage-request.service';
import { SalvageRequestTypes } from '../../enums/salvage-request-type-cd.enum';
import moment from 'moment';
import { ImageService } from '../../services/image.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FeatureService } from '../../services/feature-service/feature.service';
export interface imageDetails extends OsdImageSearchRecord {
  selected?: boolean;
}

@Component({
  selector: 'app-image-search',
  templateUrl: './image-search.component.html',
  styleUrls: ['./image-search.component.scss'],
})
export class ImageSearchComponent implements OnInit, OnDestroy {
  @Output() event = new EventEmitter<any>();
  public imageSearchFormGroup: FormGroup;
  public searchKey = '';
  public searchResults = [];
  public matchedResults = [];
  readonly OsdApprovalStatusCd = OverageApprovalStatusCd;
  public unMatchedResults = [];
  public partialMatchedResults = [];
  public uniqueSalvageRequestIds = [];
  public imageInputs: any;
  public readonly ImageSearchFormNames = ImageSearchFormNames;
  public osdImage = new OsdImage();
  public selectedDateRange: any = '';
  public fromDate: any = '';
  public toDate: any = '';
  public request = new SearchOsdImagesRqst();
  public xrtResults: OsdImageSearchRecord[] = [];
  public resultCount = 0;
  public searchEvent: any;
  public searchRan = false;
  public dateRange: any;
  public openAdvancedSearch = false;
  public unsubscribe: Subject<void> = new Subject();
  public proNumberFormatter = new ProNumberFormatterPipe(this.conditioningService);
  public activeTabIndex = 1;
  public SearchResultsTabs = SearchResultsTabs;
  public activeTabLabel = SearchResultsTabs.All;
  public allLabel = SearchResultsTabs.All + ' (0)';
  public matchedLabel = SearchResultsTabs.Matched + ' (0)';
  public unMatchedLabel = SearchResultsTabs.UNMatched + ' (0)';
  public partialMatchedLabel = SearchResultsTabs.PartialMatched + ' (0)';
  public salvageRequestLabel = SearchResultsTabs.SalvageRequest + ' (0)';
  // public defaultDate = moment().format('MM/DD/YYYY');
  public totalResults = 0;
  public page = 1;
  /*public oldDate = moment()
    .subtract(1, 'months')
    .format('MM/DD/YYYY');
    */
  public defaultDate = new Date();
  public oldDate = new Date();
  public isImageSelected: boolean = false;
  public selectedImageCount: number = 0;
  readonly SalvageFormNames = SalvageFormNames;
  isSalvageButtonDisabled$: Observable<boolean> = of(true);
  isSalvageButtonVisible$: Observable<boolean> = of(true);

  feature$: Observable<boolean>;

  @ViewChild(PaginatorComponent, { static: true })
  paginator: PaginatorComponent;
  subscription: Subscription;
  queryParams: Params;

  constructor(
    private formbuilder: FormBuilder,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private formatService: FormatValidationService,
    private notificationService: NotificationService,
    private conditioningService: ConditioningService,
    private imageUploadService: ImageUploadService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private loggingApiService: LoggingApiService,
    private shipmentApiService: ShipmentApiService,
    private salvageRequestService: SalvageRequestService,
    private imageService: ImageService,
    private featureService: FeatureService
  ) {}

  ngOnInit() {
    this.buildSearchFormGroup();
    this.paginator.pageIndex = 0;
    this.paginator.page.subscribe(pageEvent => {
      this.performSearch(pageEvent);
    });
    this.event = new EventEmitter<any>();
    this.imageInputs = null;
    const keyDowns = fromEvent(document, 'keydown').pipe(
      filter((e: KeyboardEvent) => e.key === 'Enter'),
      distinctUntilChanged()
    );
    this.subscription = keyDowns.subscribe(escPress => {
      if (escPress.type === 'keydown') {
        this.openAdvancedSearch = false;
      }
    });
    this.updateUnmatchedResults();
    this.salvageRequestService.updateSalvageButtonVisibility(true);
    this.isSalvageButtonVisible$ = this.salvageRequestService.salvageButtonVisibility$;
    this.listBrandsForSalvageForm();
    this.autoRefreshafterStatusUpdate();
    this.feature$ = this.featureService.feature$.pipe(
      takeUntil(this.unsubscribe),
      map(flag => flag)
    );

    this.salvageRequestService.overageImageSearch$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.searchImage(this.searchEvent, 1);
    });
    this.listenToRouteParams();
  }

  private listenToRouteParams(): void {
    this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
      console.log(params);
      this.queryParams = params;
      if (!!this.queryParams.osdId) {
        this.searchImage('QueryParams', 1);
      }
    });
  }

  private buildSearchFormGroup() {
    this.imageSearchFormGroup = this.formbuilder.group({
      [ImageSearchFormNames.SearchKey]: [this.searchKey],
      [ImageSearchFormNames.SelectedDateRange]: [this.selectedDateRange],
      [ImageSearchFormNames.ProNbr]: [this.osdImage.proNbr],
      [ImageSearchFormNames.ReferenceNbr]: [this.osdImage.referenceNbr],
      [ImageSearchFormNames.Description]: [this.osdImage.description],
      [ImageSearchFormNames.ReportingSicCd]: [this.osdImage.reportingSicCd],
      [ImageSearchFormNames.Color]: [this.osdImage.color],
      [ImageSearchFormNames.LengthNbr]: [this.osdImage.lengthNbr],
      [ImageSearchFormNames.WidthNbr]: [this.osdImage.widthNbr],
      [ImageSearchFormNames.HeightNbr]: [this.osdImage.heightNbr],
      [ImageSearchFormNames.PackageCd]: [this.osdImage.packageCd],
      [ImageSearchFormNames.PiecesCount]: [this.osdImage.piecesCount],
      [ImageSearchFormNames.WeightLbs]: [this.osdImage.weightLbs],
      [ImageSearchFormNames.OriginProNbr]: [this.osdImage.originProNbr],
      [ImageSearchFormNames.Brand]: [this.osdImage.brand],
      [ImageSearchFormNames.PiecesCount]: [this.osdImage.piecesCount],
      [ImageSearchFormNames.ToDate]: [
        { value: this.toDate, disabled: false },
        [
          Validators.required,
          xpoMaxDateValidator(
            moment()
              .endOf('day')
              .toDate()
          ),
        ],
      ],
      [ImageSearchFormNames.FromDate]: [
        { value: this.fromDate, disabled: false },
        [
          Validators.required,
          xpoMaxDateValidator(
            moment()
              .endOf('day')
              .toDate()
          ),
        ],
      ],
    });
    const selectedDateRangeDefault = { endDate: this.defaultDate, startDate: this.oldDate };
    // this.dateRange = { endDate: this.defaultDate, startDate: this.oldDate };
    // this.imageSearchFormGroup.get(ImageSearchFormNames.SelectedDateRange).setValue(selectedDateRangeDefault);
    this.oldDate.setMonth(this.oldDate.getMonth() - 1);
    this.imageSearchFormGroup.get(ImageSearchFormNames.FromDate).setValue(this.oldDate.toISOString());
    this.imageSearchFormGroup.get(ImageSearchFormNames.ToDate).setValue(this.defaultDate.toISOString());
    this.watchFromDate(this.imageSearchFormGroup.get(ImageSearchFormNames.ToDate));
    this.watchFromDate(this.imageSearchFormGroup.get(ImageSearchFormNames.FromDate));
  }

  private listBrandsForSalvageForm() {
    const queryParams = new ListBrandsForSalvageQuery();
    this.shipmentApiService
      .listBrandsForSalvage(queryParams)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((resp: Envelope<ListBrandsForSalvageResp>) => {
        this.salvageRequestService.setBrandList(_get(resp, 'brands'));
      });
  }

  public runAdvancedSearch(page?: number) {
    this.searchRan = false;
    this.request = new SearchOsdImagesRqst();
    this.searchResults = [];
    this.unMatchedResults = [];
    this.matchedResults = [];
    this.partialMatchedResults = [];
    const sortExp = new XrtSortExpression();
    sortExp.column = 'createdTimestamp';
    sortExp.isDescendingSort = true;
    this.request.sortExpressions = [sortExp];
    this.request.pageNumber = page;
    this.request.pageSize = this.paginator.currentPageSize;
    const proNbr = this.imageSearchFormGroup.get(ImageSearchFormNames.ProNbr).value;
    const refNbr = this.imageSearchFormGroup.get(ImageSearchFormNames.ReferenceNbr).value;
    const desc = this.imageSearchFormGroup.get(ImageSearchFormNames.Description).value;
    const sicCd = this.imageSearchFormGroup.get(ImageSearchFormNames.ReportingSicCd).value;
    const color = this.imageSearchFormGroup.get(ImageSearchFormNames.Color).value;
    const length = this.imageSearchFormGroup.get(ImageSearchFormNames.LengthNbr).value;
    const width = this.imageSearchFormGroup.get(ImageSearchFormNames.WidthNbr).value;
    const height = this.imageSearchFormGroup.get(ImageSearchFormNames.HeightNbr).value;
    const pkgCd = this.imageSearchFormGroup.get(ImageSearchFormNames.PackageCd).value;
    const qty = this.imageSearchFormGroup.get(ImageSearchFormNames.PiecesCount).value;
    const weight = this.imageSearchFormGroup.get(ImageSearchFormNames.WeightLbs).value;
    const originPro = this.imageSearchFormGroup.get(ImageSearchFormNames.OriginProNbr).value;
    const brand = this.imageSearchFormGroup.get(ImageSearchFormNames.Brand).value;
    const fromDates = this.imageSearchFormGroup.get(ImageSearchFormNames.FromDate).value;
    const toDates = this.imageSearchFormGroup.get(ImageSearchFormNames.ToDate).value;
    touchAllControls(this.imageSearchFormGroup);
    if (this.imageSearchFormGroup.valid) {
      this.salvageRequestService.updateSalvageButtonVisibility(true);
      this.isSalvageButtonDisabled$ = this.salvageRequestService.calculateDisability();
      if (proNbr || refNbr || desc || sicCd || color || length || width || height || pkgCd || qty || weight || originPro || brand) {
        const filter = this.initializeFilter();
        proNbr ? (filter.proNbr.contains = this.checkAndFormatPro(proNbr)) : '';
        refNbr ? (filter.referenceNbr.equals = refNbr) : '';
        desc ? (filter.description.contains = desc) : '';
        sicCd ? (filter.reportingSicCd.equals = sicCd) : '';
        color ? (filter.color.equals = color) : '';
        length ? (filter.lengthNbr.equals = length) : '';
        width ? (filter.widthNbr.equals = width) : '';
        height ? (filter.heightNbr.equals = height) : '';
        pkgCd ? (filter.packageCd.equals = pkgCd) : '';
        qty ? (filter.piecesCount.equals = qty) : '';
        weight ? (filter.weightLbs.equals = weight) : '';
        originPro ? (filter.originProNbr.equals = originPro) : '';
        brand ? (filter.brand.equals = brand) : '';
        if (fromDates && toDates) {
          const datefilter = new XrtAttributeFilter();
          datefilter.min = moment(fromDates).format('MM/DD/YYYY HH:mm:ss');
          const endDate = moment(toDates)
            .endOf('day')
            .utc()
            .format('MM/DD/YYYY HH:mm:ss');
          datefilter.max = endDate;
          filter.createdTimestamp = datefilter;
        }

        /**If the approvalStatus field in osd-image elastic is 'Y' or blank,
         * only then the record should be showed on the search results screen */
        const andFilters: OsdImageSearchFilterAnd[] = [];
        const andFilter = new OsdImageSearchFilterAnd();
        const approvalStatusCdOrFilters: OsdImageSearchFilterOr[] = [];
        let approvalStatusCdOrFilter = new OsdImageSearchFilterOr();
        approvalStatusCdOrFilter.approvalStatusCd = { ...new XrtAttributeFilter(), equals : OverageApprovalStatusCd.APPROVED };
        approvalStatusCdOrFilters.push(approvalStatusCdOrFilter);

        approvalStatusCdOrFilter = new OsdImageSearchFilterOr();
        approvalStatusCdOrFilter.approvalStatusCd = { ...new XrtAttributeFilter(), exists : false }
        approvalStatusCdOrFilters.push(approvalStatusCdOrFilter);

        andFilter.or = approvalStatusCdOrFilters;
        andFilters.push(andFilter);

        if (andFilters.length) {
          filter.and = andFilters;
        }

        this.loggingApiService.info(JSON.stringify(filter), 'Search Key', 'Advanced Search', 'Search');
        this.request.filter = filter;
        this.paginator.currentPageIndex = page - 1;
        this.shipmentApiService
          .searchOsdImages(this.request)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            res => {
              this.searchRan = true;
              if (res) {
                this.xrtResults = _get(res, 'result');
                const count = res['header'].resultCount;
                this.paginator.length = count;
                this.transformtoModel(this.xrtResults);
                this.searchRan = true;
              }
            },
            error => {
              this.searchRan = true;
            }
          );
      } else {
        this.notificationService.showSnackBarMessage('Atleast one search criteria required. Please enter atleast one search term.', {
          durationInMillis: 3000,
          status: 'ERROR',
        });
      }
    } else {
      this.notificationService.showSnackBarMessage('Please fix the errors above before searching', {
        durationInMillis: 3000,
        status: 'ERROR',
      });
    }
  }

  private initializeFilter(): OsdImageSearchFilter {
    const filter: OsdImageSearchFilter = new OsdImageSearchFilter();
    filter.proNbr = new XrtAttributeFilter();
    filter.referenceNbr = new XrtAttributeFilter();
    filter.description = new XrtAttributeFilter();
    filter.reportingSicCd = new XrtAttributeFilter();
    filter.color = new XrtAttributeFilter();
    filter.lengthNbr = new XrtAttributeFilter();
    filter.widthNbr = new XrtAttributeFilter();
    filter.heightNbr = new XrtAttributeFilter();
    filter.packageCd = new XrtAttributeFilter();
    filter.piecesCount = new XrtAttributeFilter();
    filter.weightLbs = new XrtAttributeFilter();
    filter.brand = new XrtAttributeFilter();
    return filter;
  }

  public searchImage($searchEvent, page?: number) {
    this.totalResults = 0;
    this.uniqueSalvageRequestIds = [];
    this.page = page;
    this.searchEvent = $searchEvent;
    if (($searchEvent && ($searchEvent.key === 'Enter' || $searchEvent === 'KeyPress')) || $searchEvent.type === 'click' || $searchEvent === 'QueryParams') {
      if ($searchEvent !== 'QueryParams') {
        this.deleteQueryParams();
      }
      this.selectedImageCount = 0;
      this.salvageRequestService.updatePROList([]);
      this.salvageRequestService.updateActiveTab(SearchResultsTabs.UNMatched);
      this.isSalvageButtonDisabled$ = this.salvageRequestService.calculateDisability();
      this.salvageRequestService.updateSalvageButtonVisibility(true);
      if (!!this.queryParams?.osdId) {
        this.searchKey = this.queryParams?.osdId;
      } else {
        this.searchKey = $searchEvent.target && $searchEvent.target.value ? $searchEvent.target.value : this.imageSearchFormGroup.get(ImageSearchFormNames.SearchKey).value;
      }

      this.searchKey = _trim(this.searchKey);
      // const dateRange = this.imageSearchFormGroup.get(ImageSearchFormNames.SelectedDateRange).value;
      const fromDates = this.imageSearchFormGroup.get(ImageSearchFormNames.FromDate).value;
      const toDates = this.imageSearchFormGroup.get(ImageSearchFormNames.ToDate).value;
      touchAllControls(this.imageSearchFormGroup);
      if (this.imageSearchFormGroup.valid) {
        if (this.searchKey) {
          this.searchResults = [];
          this.unMatchedResults = [];
          this.matchedResults = [];
          this.partialMatchedResults = [];
          const sortExp = new XrtSortExpression();
          sortExp.column = 'createdTimestamp';
          sortExp.isDescendingSort = true;
          this.loggingApiService.info(this.searchKey, 'Search Key', 'Fuzzy Search', 'Search');

          // const searchArray = this.searchKey.split(',');
          // Start value instead of array
          const searchArray = [];
          searchArray.push(this.searchKey);
          // End value instead of array

          searchArray.forEach(key => {
            const orFilters: OsdImageSearchFilterOr[] = [];
            const andFilters: OsdImageSearchFilterAnd[] = [];
            const filter: OsdImageSearchFilter = new OsdImageSearchFilter();
            const { value: searchKey, isProSearch: isProSearch } = this.checkAndFormatProOnSearch(key);
            if (isProSearch) {
              const filterInner1: OsdImageSearchFilter = new OsdImageSearchFilter();
              filterInner1.b = searchKey;
              orFilters.push(filterInner1);
              const filterInner2: OsdImageSearchFilter = new OsdImageSearchFilter();
              filterInner2.b = '0' + searchKey;
              orFilters.push(filterInner2);
              if (orFilters.length) {
                filter.or = orFilters;
              }
            } else {
              filter.b = searchKey;
            }

            if (!!this.queryParams?.osdId) {
              const datefilter = new XrtAttributeFilter();
              if (!!this.queryParams?.osdFromDate) {
                const fromDate = new Date(+this.queryParams?.osdFromDate);
                datefilter.min = moment(fromDate)
                  .utc()
                  .format('MM/DD/YYYY HH:mm:ss');
              }
              const toDate = new Date();
              const endDate = moment(toDate)
                .endOf('day')
                .utc()
                .format('MM/DD/YYYY HH:mm:ss');
              datefilter.max = endDate;
              filter.createdTimestamp = datefilter;
            } else {
              if (fromDates && toDates) {
                const datefilter = new XrtAttributeFilter();
                datefilter.min = moment(fromDates).format('MM/DD/YYYY HH:mm:ss');
                const endDate = moment(toDates)
                  .endOf('day')
                  .utc()
                  .format('MM/DD/YYYY HH:mm:ss');
                datefilter.max = endDate;
                filter.createdTimestamp = datefilter;
              }
            }

            /**If the approvalStatus field in osd-image elastic is 'Y' or blank,
             * only then the record should be showed on the search results screen */
            const andFilter = new OsdImageSearchFilterAnd();
            const approvalStatusCdOrFilters: OsdImageSearchFilterOr[] = [];
            let approvalStatusCdOrFilter = new OsdImageSearchFilterOr();
            approvalStatusCdOrFilter.approvalStatusCd = { ...new XrtAttributeFilter(), equals : OverageApprovalStatusCd.APPROVED };
            approvalStatusCdOrFilters.push(approvalStatusCdOrFilter);

            approvalStatusCdOrFilter = new OsdImageSearchFilterOr();
            approvalStatusCdOrFilter.approvalStatusCd = { ...new XrtAttributeFilter(), exists : false }
            approvalStatusCdOrFilters.push(approvalStatusCdOrFilter);

            andFilter.or = approvalStatusCdOrFilters;
            andFilters.push(andFilter);

            if (andFilters.length) {
              filter.and = andFilters;
            }

            this.request.filter = filter;
            this.request.sortExpressions = [sortExp];
            this.request.pageNumber = page;
            this.paginator.currentPageIndex = page - 1;
            this.request.pageSize = this.paginator.currentPageSize;
            //this.request.header = xrtHeader;
            this.shipmentApiService
              .searchOsdImages(this.request)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe(
                res => {
                  this.searchRan = true;
                  if (res) {
                    this.xrtResults = _get(res, 'result');
                    const count = res['header'].resultCount;
                    this.paginator.length = count;
                    this.totalResults = count;
                    _get(res, 'result').forEach(element => {
                      if (element.salvageRequestId) {
                        this.uniqueSalvageRequestIds.push(element.salvageRequestId);
                      }
                    });
                    this.transformtoModel(this.xrtResults);
                    this.searchRan = true;
                  } else {
                    console.log('empty');
                  }
                },
                error => {
                  this.searchRan = true;
                }
              );
          });
        } else {
          this.notificationService.showSnackBarMessage('Atleast one search criteria required. Please enter atleast one search term.', {
            durationInMillis: 3000,
            status: 'ERROR',
          });
        }
      } else {
        this.notificationService.showSnackBarMessage('Please fix the errors above before searching', {
          durationInMillis: 3000,
          status: 'ERROR',
        });
      }
    } else {
      if ($searchEvent && $searchEvent.key === 'Escape') this.searchRan = this.searchRan ? true : false;
    }
  }

  private transformtoModel(results: OsdImageSearchRecord[]) {
    if (results && results.length > 0) {
      for (let i = 0; i < results.length; i++) {
        const record = {
          proNbr: this.proNumberFormatter.conditionProNumber(results[i].proNbr, 10),
          reportingSicCd: results[i].reportingSicCd ? results[i].reportingSicCd.toUpperCase() : '',
          instId: results[i].instId,
          brand: results[i].brand,
          referenceNbr: results[i].referenceNbr,
          color: results[i].color,
          description: results[i].description,
          selectedTags: results[i].selectedTags,
          dmsUrl: results[i].dmsUrl,
          heightNbr: results[i].heightNbr,
          lengthNbr: results[i].lengthNbr,
          weightLbs: results[i].weightLbs,
          widthNbr: results[i].widthNbr,
          comments: results[i].comments,
          originProNbr: this.getProNumberInFormat(results[i].originProNbr),
          overPairedWithShortInd: results[i].overPairedWithShortInd,
          packageCd: results[i].packageCd,
          piecesCount: results[i].piecesCount,
          createdTimestamp: moment(new Date(results[i].createdTimestamp)).format('MM/DD/YYYY'),

          thumbnailImage: this.imageUrl(results[i]?.thumbnailImage),
          partialPairedWithShortInd: results[i].partialPairedWithShortInd,
          selected: false,
          salvageRequestId: results[i].salvageRequestId,
        };
        if (this.searchResults.findIndex(searchres => searchres.instId === record.instId) < 0) {
          this.searchResults.push(record);
        }
        if (record.overPairedWithShortInd) {
          if (this.matchedResults.findIndex(searchres => searchres.instId === record.instId) < 0) {
            this.matchedResults.push(record);
          }
        } else if (record.partialPairedWithShortInd) {
          if (this.partialMatchedResults.findIndex(searchres => searchres.instId === record.instId) < 0) {
            this.partialMatchedResults.push(record);
          }
        } else {
          if (this.unMatchedResults.findIndex(searchres => searchres.instId === record.instId) < 0) {
            record.originProNbr = '';
            this.unMatchedResults.push(record);
          }
        }
      }
    }
    this.resultCount = this.searchResults.length;
    this.allLabel = SearchResultsTabs.All + ' (' + this.resultCount + ')';
    this.matchedLabel = SearchResultsTabs.Matched + ' (' + this.matchedResults.length + ')';
    this.unMatchedLabel = SearchResultsTabs.UNMatched + ' (' + this.unMatchedResults.length + ')';
    this.partialMatchedLabel = SearchResultsTabs.PartialMatched + ' (' + this.partialMatchedResults.length + ')';
  }

  private imageUrl(thumbnailImage: string): string {
    if (!thumbnailImage) {
      return;
    }

    if (thumbnailImage.startsWith('data:image')) {
      return thumbnailImage;
    } else {
      return `data:image/jpeg;base64,${thumbnailImage}`;
    }
  }

  private updateUnmatchedResults() {
    this.salvageRequestService.lastDeletedPROSubject$.pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
      this.unMatchedResults.forEach((element, index) => {
        if (element.proNbr === resp) {
          this.unMatchedResults[index].selected = false;
          return;
        }
      });
    });
  }

  private getProNumberInFormat(proNumber: String) {
    if (proNumber != undefined) {
      return proNumber.slice(0, 3) + '-' + proNumber.slice(3);
    }
    return '';
  }

  public enableAdvancedSearch() {
    this.openAdvancedSearch = true;
  }

  private resetTabs() {
    this.activeTabLabel = this.SearchResultsTabs.All;
  }

  private shouldHide(tab: SearchResultsTabs): boolean {
    if (this.activeTabLabel === this.SearchResultsTabs.All) {
      return false;
    }
    this.resetTabs();
    return tab !== this.activeTabLabel;
  }

  private tabChanged(tabEvent: MatTabChangeEvent) {
    this.activeTabLabel = <SearchResultsTabs>tabEvent.tab.textLabel;
    this.salvageRequestService.updateActiveTab(this.activeTabLabel);
    this.isSalvageButtonDisabled$ = this.salvageRequestService.calculateDisability();
    this.salvageRequestService.updateSalvageButtonVisibility(true);
  }

  autoRefreshafterStatusUpdate() {
    this.imageService.imageSearchRefresh$.pipe(take(1)).subscribe(resp => {
      if (resp) {
        this.searchImage(this.searchEvent, this.page);
      }
    });
  }

  public uploadImage(event: any) {
    if (event && event.target) {
      if (event.target.files.length > 6) {
        alert('Please select 6 or less images');
        return;
      } else if (event.target.files) {
        const fileTypes = Array.from(event.target.files).map(file => {
          return file['type'];
        });
        let invalidCount = 0;
        fileTypes.forEach(type => {
          if (type !== 'image/jpg' && type !== 'image/jpeg') {
            invalidCount++;
          }
        });
        if (invalidCount > 0) {
          alert('Only JPEG/JPG images allowed. ' + invalidCount + ' file(s) of invalid type have been found.');
          return;
        }

        // event = null;
      }
      this.imageUploadService.setEvent(event);
      this.event = event;
      this.imageInputs = null;
      this.router.navigate([RouterUriComponents.IMAGE_UPLOAD_PAGE]);
    }
  }

  public runSearch() {
    if (this.openAdvancedSearch) {
      this.runAdvancedSearch(1);
    } else {
      this.searchImage('KeyPress', 1);
    }
  }

  private openDetailsView($event: any, resultRecord: any) {
    const result = resultRecord || new OsdImageSearchRecord();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.position = {
      // top: ($event.y-30) + 'px',
      // left:( $event.x) + 'px'
      // top: '100px',
      // left: '100px',
    };
    dialogConfig.width = '98%';
    dialogConfig.height = '89%';
    dialogConfig.maxWidth = '90%';
    dialogConfig.backdropClass = 'center-modal';
    // dialogConfig.height = '750px';
    if (result) {
      dialogConfig.data = { result: result };
      this.dialog
        .open(ImageDetailsComponent, dialogConfig)
        .afterClosed()
        .pipe(take(1))
        .subscribe((closeResponse: ImageUpdateActions) => {
          if (closeResponse) {
            if (closeResponse !== ImageUpdateActions.Updated) {
              this.notificationService.showSnackBarMessage(`Image(s) ${closeResponse} Succesfully`, {
                durationInMillis: 3000,
                status: 'success',
              });
            }
            this.searchImage(this.searchEvent, this.page);
          }
        });
    }
  }

  private cancelAdvancedSearch() {
    this.openAdvancedSearch = false;
  }

  private checkAndFormatPro(key) {
    if ((key.length === 11 || key.length === 10) && this.checkNbr(key.split('-'))) {
      const formattedPro = this.conditioningService.conditionProNumber(key, 9);
      return formattedPro;
    }
    return key;
  }

  private checkAndFormatProOnSearch(searchKey): { isProSearch: boolean; value: string } {
    if ((searchKey.length === 11 || searchKey.length === 10) && this.checkNbr(searchKey.split('-'))) {
      const formattedPro = this.conditioningService.conditionProNumber(searchKey, 9);
      return { isProSearch: true, value: formattedPro };
    }
    return { isProSearch: false, value: searchKey };
  }

  private checkNbr(x) {
    return x.every(function(i) {
      return !isNaN(i);
    });
  }

  public performSearch(pageEvent: any) {
    if (this.openAdvancedSearch) {
      this.runAdvancedSearch(pageEvent.pageIndex + 1);
    } else {
      this.searchImage(this.searchEvent, pageEvent.pageIndex + 1);
    }
  }

  public hasError(error: string, formGrp?: FormGroup, field?: ImageSearchFormNames): boolean {
    if (formGrp && formGrp.hasError(error)) {
      return formGrp.hasError(error) && formGrp.touched;
    } else if (formGrp && field) {
      return formGrp.get(field).hasError(error) && formGrp.get(field).touched;
    }
  }

  private watchFromDate(watchControl: AbstractControl) {
    watchControl.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.unsubscribe), debounceTime(100)).subscribe(value => {
      if (value) {
        this.validatedate(value);
      }
    });
  }

  formatProNbr(proNbr) {
    return this.proNumberFormatter.conditionProNumber(proNbr, 10);
  }

  formatTimestamp(timestamp) {
    return moment(new Date(timestamp)).format('MM/DD/YYYY');
  }

  formatReqType(reqType: string) {
    for (var type in SalvageRequestTypes) {
      if (SalvageRequestTypes[type] === reqType) {
        return type;
      }
    }
  }

  private validatedate(date: any) {
    const fromDate = Date.parse(this.imageSearchFormGroup.get(ImageSearchFormNames.FromDate).value.toString());
    const toDate = Date.parse(this.imageSearchFormGroup.get(ImageSearchFormNames.ToDate).value.toString());
    if (fromDate > toDate) {
      this.imageSearchFormGroup.get(ImageSearchFormNames.FromDate).setErrors({ moreThanToDate: true });
    } else {
      this.imageSearchFormGroup.get(ImageSearchFormNames.FromDate).setErrors(null);
    }
  }

  imageSelected(ind: number) {
    if (this.unMatchedResults[ind].selected) {
      this.selectedImageCount++;
    } else {
      this.selectedImageCount--;
    }
    const selectedPROList = [];
    this.unMatchedResults.forEach(result => {
      if (result.selected === true) {
        selectedPROList.push(result);
      }
    });
    this.salvageRequestService.updatePROList(selectedPROList);
    this.isSalvageButtonDisabled$ = this.salvageRequestService.calculateDisability();
  }

  openSalvageForm() {
    this.salvageRequestService.updateSalvageButtonVisibility(false);
  }

  isSalvageButtonDisabled() {
    return this.salvageRequestService.calculateDisability();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  deleteQueryParams() {
    this.queryParams = undefined;
    this.router.navigate([], {
      queryParams: {
        osdId: null,
        osdFromDate: null,
      },
      queryParamsHandling: 'merge',
    });
  }
}
