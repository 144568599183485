export enum SalvageFormNames{
    RequestName = 'RequestName',
    RequestType = 'RequestType',
    SIC = "SIC",
    FreightDescription = "freightDescription",
    Ref = "Ref",
    Brand = 'Brand',
    PackagingType = 'PackagingType',
    SpecialHandling = 'SpecialHandling',
    TotalWeight = 'TotalWeight',
    LengthNbr = 'LengthNbr',
    WidthNbr = 'WidthNbr',
    HeightNbr = 'HeightNbr',
    CubeNbr = 'CubeNbr',
    QualityofFreight = 'QualityOfFreight',
    ValueofFreight = 'ValueofFreight',
    Comments = 'Comments',
    VisibleInd = 'VisibleInd',
    SearchInd = 'SearchInd',
    DamageInformationInd = 'DamageInformationInd',
    OldComments = "oldComments",
    ParentPRO = "ParentPro",
    ChildPROs = "ChildPROs"
}