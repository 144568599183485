export enum ColorsClassEnum {
  RED = 'fillClass_red',
  BLUE = 'fillClass_blue',
  GREEN = 'fillClass_green',
  YELLOW = 'fillClass_yellow',
  ORANGE = 'fillClass_orange',
  PURPLE = 'fillClass_purple',
  BLACK = 'fillClass_black',
  BROWN = 'fillClass_brown',
  WHITE = 'fillClass_white',
  GREY = 'fillClass_grey',
}
