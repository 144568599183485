import {OsdImage, UpsertShipmentOverageImageRqst } from '@xpo-ltl-2.0/sdk-shipment';
import { CommodityPackageCdHelper } from '@xpo-ltl/sdk-common';
import { Injectable } from '@angular/core';

@Injectable()
export class ImageUploadTransformer {
  commodityPackageCdHelper = CommodityPackageCdHelper;
  public toUpsertShipmentImageDetailsRqst(overageHeader: OsdImage, prevComments?: string, userAction?: string): UpsertShipmentOverageImageRqst {
    const upsertShipmentImageDetailsRqst = new UpsertShipmentOverageImageRqst();

    upsertShipmentImageDetailsRqst.overageImageHeader = overageHeader;
    upsertShipmentImageDetailsRqst.overageImageHeader.description = upsertShipmentImageDetailsRqst.overageImageHeader.description ? upsertShipmentImageDetailsRqst.overageImageHeader?.description?.toString() : '';
    upsertShipmentImageDetailsRqst.overageImageHeader.selectedTags = upsertShipmentImageDetailsRqst.overageImageHeader.selectedTags
      ? upsertShipmentImageDetailsRqst.overageImageHeader?.selectedTags?.toString()
      : '';
    upsertShipmentImageDetailsRqst.overageImageHeader.referenceNbr = upsertShipmentImageDetailsRqst.overageImageHeader.referenceNbr
      ? upsertShipmentImageDetailsRqst.overageImageHeader?.referenceNbr?.toString()
      : '';
    if (upsertShipmentImageDetailsRqst.overageImageHeader.packageCd) {
      upsertShipmentImageDetailsRqst.overageImageHeader.packageCd = upsertShipmentImageDetailsRqst.overageImageHeader.packageCd.substring(0, 3);
    }
    if (upsertShipmentImageDetailsRqst.overageImageHeader.reportingSicCd) {
      upsertShipmentImageDetailsRqst.overageImageHeader.reportingSicCd = upsertShipmentImageDetailsRqst.overageImageHeader.reportingSicCd.trim();
    }
    upsertShipmentImageDetailsRqst.overageImageHeader.color = upsertShipmentImageDetailsRqst.overageImageHeader.color ? upsertShipmentImageDetailsRqst.overageImageHeader?.color?.toString() : '';

    if ((upsertShipmentImageDetailsRqst.overageImageHeader.proNbr && upsertShipmentImageDetailsRqst.overageImageHeader.originProNbr && userAction === 'SAVEAFTEREDIT') || userAction === 'MATCH') {
      upsertShipmentImageDetailsRqst.overageImageHeader.overPairedWithShortInd = true;
    } else if (!upsertShipmentImageDetailsRqst.overageImageHeader.overPairedWithShortInd) {
      upsertShipmentImageDetailsRqst.overageImageHeader.overPairedWithShortInd = false;
    }

    if (!upsertShipmentImageDetailsRqst.overageImageHeader.partialPairedWithShortInd) {
      upsertShipmentImageDetailsRqst.overageImageHeader.partialPairedWithShortInd = false;
    }
    delete upsertShipmentImageDetailsRqst.overageImageHeader['uploadDate'];
    delete upsertShipmentImageDetailsRqst.overageImageHeader['auditInfo'];
    const refNbrs = upsertShipmentImageDetailsRqst.overageImageHeader['referenceNbr'];
    if (refNbrs && !upsertShipmentImageDetailsRqst.overageImageHeader['instId']) {
      upsertShipmentImageDetailsRqst.overageImageHeader['referenceNbr'] = refNbrs.replace(/,/g, ', ');
    }
    if (prevComments) {
      upsertShipmentImageDetailsRqst.overageImageHeader.comment = prevComments + ' - ' + upsertShipmentImageDetailsRqst.overageImageHeader.comment;
    }
    upsertShipmentImageDetailsRqst.overageImageHeader.proNbr = upsertShipmentImageDetailsRqst.overageImageHeader.proNbr.replace('-', '');
    if (upsertShipmentImageDetailsRqst.overageImageHeader.originProNbr) {
      upsertShipmentImageDetailsRqst.overageImageHeader.originProNbr = upsertShipmentImageDetailsRqst.overageImageHeader.originProNbr.replace('-', '');
    }
    if(userAction === 'SAVEAFTEREDIT' && upsertShipmentImageDetailsRqst?.overageImageHeader?.originProNbr === ''){
      upsertShipmentImageDetailsRqst.overageImageHeader.overPairedWithShortInd = false;
    }
    return upsertShipmentImageDetailsRqst;
  }

  public toUpdateShipmentImageDetailsRqst(overageHeader: OsdImage, showMatch: boolean): UpsertShipmentOverageImageRqst {
    const upsertShipmentImageDetailsRqst = new UpsertShipmentOverageImageRqst();
    upsertShipmentImageDetailsRqst.overageImageHeader = overageHeader;
    upsertShipmentImageDetailsRqst.overageImageHeader.instId = overageHeader.instId;
    upsertShipmentImageDetailsRqst.overageImageHeader.overPairedWithShortInd = showMatch;
    if (showMatch) {
      upsertShipmentImageDetailsRqst.overageImageHeader.originProNbr = overageHeader.originProNbr;
      upsertShipmentImageDetailsRqst.overageImageHeader.comment = overageHeader.comment;
    } else {
      upsertShipmentImageDetailsRqst.overageImageHeader.originProNbr = '';
      upsertShipmentImageDetailsRqst.overageImageHeader.comment = overageHeader.comment;
    }
    return upsertShipmentImageDetailsRqst;
  }
}
