import { Injectable } from '@angular/core';
import { User } from '@xpo-ltl/sdk-common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserRoleService {
  private readonly DEV_APPROVER_ROLE: string = 'TST_LTL_SALVAGE_ADMIN';
  private readonly PROD_APPROVER_ROLE: string = 'LTL_SALVAGE_ADMIN';

  private isUserApproverSubject = new BehaviorSubject<boolean>(false);
  isUserApprover$ = this.isUserApproverSubject.asObservable();

  constructor() {}

  setIsUserApprover(user: User): void {
    const isApprover: boolean = this.hasRoleAssigned(user.roles, this.DEV_APPROVER_ROLE) || this.hasRoleAssigned(user.roles, this.PROD_APPROVER_ROLE);
    this.isUserApproverSubject.next(isApprover);
  }

  private hasRoleAssigned(roles: string[], forRole: string): boolean {
    const roleToSearchFor = `${forRole?.toUpperCase()}`;
    const foundRoles = roles?.find(role => role?.toUpperCase()?.indexOf(roleToSearchFor) >= 0);
    return foundRoles?.length > 0;
  }
}
