import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  private featureSubject = new BehaviorSubject<boolean>(false);
  readonly feature$ = this.featureSubject.asObservable();
  
  constructor() { }

  setFeature(flag:boolean){
    this.featureSubject.next(flag)
  }
}
