import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from './paginator/paginator.component';
import { MaterialModule } from '../material.module';
import { XpoMaxDateValidatorDirective } from './validators/max-date.validators';



@NgModule({
  declarations: [PaginatorComponent, XpoMaxDateValidatorDirective],
  exports: [PaginatorComponent],
  imports: [
    CommonModule,
    MaterialModule
  ]
})
export class CoreModule { }
