import { Injectable } from '@angular/core';
import { CreateSalvageRequestResp, CreateSalvageRequestRqst, OsdImage, SalvageRequest, ShipmentApiService } from '@xpo-ltl-2.0/sdk-shipment';
import { Envelope, SalvageRequestTypeCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { FreightValueType } from '../../enums/freight-value-type.enum';
import { SalvageFormNames } from '../../enums/salvage-form-names.enum';
import { SalvageRequestTypes } from '../../enums/salvage-request-type-cd.enum';
import { SearchResultsTabs } from '../../enums/search-results-tabs.enum';

@Injectable({
  providedIn: 'root',
})
export class SalvageRequestService {
  private salvagePROListSubject = new BehaviorSubject<any>([]);
  public salvagePROList$ = this.salvagePROListSubject.asObservable();

  private lastDeletedPROSubject = new BehaviorSubject<any>('');
  public lastDeletedPROSubject$ = this.lastDeletedPROSubject.asObservable();

  private salvageButtonVisibilitySubject = new BehaviorSubject<boolean>(true);
  public salvageButtonVisibility$ = this.salvageButtonVisibilitySubject.asObservable();

  private activeTabSubject = new BehaviorSubject<SearchResultsTabs>(SearchResultsTabs.UNMatched);
  public activeTabt$ = this.activeTabSubject.asObservable();

  private brandListSubject = new BehaviorSubject<string[]>([]);
  public brandList$ = this.brandListSubject.asObservable();

  public overageImageSearchSubject = new Subject();
  readonly overageImageSearch$ = this.overageImageSearchSubject.asObservable();

  constructor(private shipmentApiService: ShipmentApiService) {}

  public updateSalvageButtonVisibility(visibility: boolean) {
    this.salvageButtonVisibilitySubject.next(visibility);
  }

  public updatePROList(proArray) {
    this.salvagePROListSubject.next(proArray);
  }

  public updatelastDeletedPRO(pro) {
    this.lastDeletedPROSubject.next(pro);
    this.salvagePROListSubject.next(this.salvagePROListSubject.value.filter(item => item.proNbr !== pro));
  }

  public updateActiveTab(newTab: SearchResultsTabs) {
    this.activeTabSubject.next(newTab);
  }

  public setBrandList(brandList: string[]): void {
    this.brandListSubject.next(brandList);
  }

  areAllElementsEqual(array) {
    const result = array.every(element => {
      if (element === array[0]) {
        return true;
      }
    });
    return result;
  }

  public createSalvageRequest(formData, isAlreadyExisting: boolean): Observable<Envelope<CreateSalvageRequestResp>> {
    const qualityOfFreight = formData?.[SalvageFormNames.QualityofFreight].split('-')[0].trim();
    const request: CreateSalvageRequestRqst = {
      salvageRequest: {
        ... new SalvageRequest(),
        salvageRequestName: formData?.[SalvageFormNames.RequestName],
        salvageRequestTypeCd: SalvageRequestTypes[formData?.[SalvageFormNames.RequestType]],
        sicCd: formData?.[SalvageFormNames.SIC],
        brand: formData?.[SalvageFormNames.Brand],
        packagingCd: formData?.[SalvageFormNames.PackagingType].split('-')[0].trim(),
        specialHandlingInd: formData?.[SalvageFormNames.SpecialHandling],
        weightLbs: +formData?.[SalvageFormNames.TotalWeight],
        lengthNbr: +formData?.[SalvageFormNames.LengthNbr],
        widthNbr: +formData?.[SalvageFormNames.WidthNbr],
        heightNbr: +formData?.[SalvageFormNames.HeightNbr],
        cubeNbr: +formData?.[SalvageFormNames.CubeNbr],
        freightConditionCd: qualityOfFreight,
        estimatedValueCd: FreightValueType[formData?.[SalvageFormNames.ValueofFreight]],
        osdImage: this.createOsdImage(),
        piecesCount: this.getPiecesCount(),
        comment: formData?.[SalvageFormNames.Comments],
        visibleInd: formData?.[SalvageFormNames.VisibleInd],
        searchInd: formData?.[SalvageFormNames.SearchInd],
        damageInformationInd: false,
        holdForVendorInd: false,
        qualifyTypeCd: 'OVERAGE',
        freightDescription: formData?.[SalvageFormNames.FreightDescription],
      } as SalvageRequest,
    } as CreateSalvageRequestRqst;
    if (isAlreadyExisting) {
      request.childProNbrs = formData?.[SalvageFormNames.ChildPROs].map(a => a.childPRONumber);
      request.salvageRequest.proNbr = formData?.[SalvageFormNames.ParentPRO];
    }
    return this.shipmentApiService.createSalvageRequest(request);
  }

  private createOsdImage(): OsdImage[] {
    const osdImage: OsdImage[] = [];
    this.salvagePROListSubject.value.forEach(val => {
      osdImage.push({
        instId: val?.instId,
        proNbr: val?.proNbr?.replace('-', ''),
      } as OsdImage);
    });
    return osdImage;
  }

  private getPiecesCount(): number {
    let totalPiecesCount: number = 0;
    this.salvagePROListSubject.value.forEach(val => {
      totalPiecesCount += val?.piecesCount;
    });
    return totalPiecesCount;
  }

  public calculateDisability(): Observable<boolean> {
    if (this.activeTabSubject.value.split(' ')[0] === SearchResultsTabs.UNMatched) {
      if (this.salvagePROListSubject.value.length === 0) {
        return of(true);
      } else {
        // check if all selected shipments have same reportingSIC
        const selectedSICList = this.salvagePROListSubject.value.map(item => item.reportingSicCd);
        if (!this.areAllElementsEqual(selectedSICList)) {
          return of(true);
        } else {
          //check if only one 9 digit PRO or only 10 digit PROs are selected
          const selectedPROListLength = this.salvagePROListSubject.value.map(item => item.proNbr.replace('-', '').length);
          if (this.areAllElementsEqual(selectedPROListLength)) {
            if (selectedPROListLength[0] === 9 && selectedPROListLength.length > 1) {
              return of(true);
            } else {
              return of(false);
            }
          } else {
            return of(true);
          }
        }
      }
    } else {
      return of(true);
    }
  }

  public deletePROfromList(proNbr: Number) {
    this.salvagePROListSubject.next(this.salvagePROListSubject.value.filter(item => item.proNbr !== proNbr));
  }
}
