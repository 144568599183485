<div>
  <div class="salvageFormHeader">
    <div class="salvageFormHeading">New Salvage Request</div>
  </div>

  <form [formGroup]="salvageForm" class="salvageRequest__form">
    <div class="salvageRequest__form-container">
      <div class="salvageRequest__form-upper-container">
        <div class="salvageRequest__form-wrapper">
          <p class="salvageRequest__form-piecesCountText">
            Total Pieces : <span class="salvageRequest__form-piecesCount">{{ totalPiecesCount }}</span>
          </p>

          <div *ngIf="isAlreadyExisting$ | async">
            <mat-form-field appearance="outline" class="statusUpdateForm_form-formField">
              <mat-label>Parent PRO</mat-label>
              <input matInput [formControlName]="SalvageFormNames.ParentPRO" autocomplete="off" required />
            </mat-form-field>
            <ng-container [formArrayName]="SalvageFormNames.ChildPROs">
              <ng-container *ngFor="let child of childpros.controls; let i = index">
                <div [formGroup]="child">
                  <mat-form-field appearance="outline" class="statusUpdateForm_form-formField">
                    <mat-label>Child PRO {{ i + 1 }}</mat-label>
                    <input matInput formControlName="childPRONumber" autocomplete="off" required />
                  </mat-form-field>
                </div>
              </ng-container>
            </ng-container>
            <div class="child-pro-button">
              <span class="child-pro-button-add" (click)="addChildPRO()" [hidden]="childpros.length === 15">+ Add Child PRO</span>
              <span class="child-pro-button-remove" (click)="removeChildPRO()" [hidden]="childpros.length < 2">- Remove Child PRO</span>
            </div>
          </div>

          <mat-form-field class="salvageRequest__form-formField salvageRequest__form-requestName" appearance="outline">
            <mat-label>Request Name<span class="mandatory-field">*</span></mat-label>
            <input matInput [formControlName]="SalvageFormNames.RequestName" placeholder="Keywords" autocomplete="off" />
            <mat-error *ngIf="getControl(SalvageFormNames.RequestName).touched && getControl(SalvageFormNames.RequestName).invalid">
              <div *ngIf="getControl(SalvageFormNames.RequestName).errors.required">Request Name is required</div>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="salvageRequest__form-formField salvageRequest__form-requestType">
            <mat-label>Request Type<span class="mandatory-field">*</span></mat-label>
            <mat-select [formControlName]="SalvageFormNames.RequestType">
              <mat-option *ngFor="let requestType of requestTypes" [value]="requestType">
                {{ requestType }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="getControl(SalvageFormNames.RequestType).touched && getControl(SalvageFormNames.RequestType).invalid">
              <div *ngIf="getControl(SalvageFormNames.RequestType).errors.required">Request Type is required</div>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="salvageRequest__form-formField salvageRequest__form-sic" appearance="outline">
            <mat-label>SIC<span class="mandatory-field">*</span></mat-label>
            <input matInput [formControlName]="SalvageFormNames.SIC" autocomplete="off" oninput="this.value = this.value.toUpperCase()" />
            <mat-error *ngIf="getControl(SalvageFormNames.SIC).touched && getControl(SalvageFormNames.SIC).invalid" class="salvageRequest__form-error">
              <div *ngIf="getControl(SalvageFormNames.SIC).errors.required">SIC is required</div>
            </mat-error>
            <mat-error *ngIf="hasError('invalidSicCode', salvageForm, SalvageFormNames.SIC)"> SIC Code is invalid </mat-error>
          </mat-form-field>

          <mat-form-field class="salvageRequest__form-formField salvageRequest__form-pdct-desc" appearance="outline">
            <mat-label>Product Description<span class="mandatory-field">*</span></mat-label>
            <textarea matInput [formControlName]="SalvageFormNames.FreightDescription" maxlength="4000" placeholder="Product Description"></textarea>
          </mat-form-field>

          <mat-form-field class="salvageRequest__form-formField salvageRequest__form-ref" appearance="outline">
            <mat-label>Reference #'s (Model #, Serial #, UPC #) </mat-label>
            <input matInput [formControlName]="SalvageFormNames.Ref" maxlength="4000" autocomplete="off" />
          </mat-form-field>
          <div class="salvageRequest__form-duoPanel">
            <mat-form-field appearance="outline" class="salvageRequest__form-formField salvageRequest__form-packagingType">
              <mat-label>Brand<span class="mandatory-field">*</span></mat-label>
              <input type="text" matInput autocomplete="off" placeholder="Select" [formControlName]="SalvageFormNames.Brand" [matAutocomplete]="brandAutocomplete" />
              <mat-autocomplete #brandAutocomplete="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let brand of filteredOptionsBrand$ | async" [value]="brand">{{ brand }}</mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="getControl(SalvageFormNames.Brand).touched && getControl(SalvageFormNames.Brand).invalid">
                <div *ngIf="getControl(SalvageFormNames.Brand).errors.required">Brand is required</div>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="salvageRequest__form-duoPanel">
            <mat-form-field appearance="outline" class="salvageRequest__form-formField salvageRequest__form-packagingType">
              <mat-label>Packaging Type<span class="mandatory-field">*</span></mat-label>
              <input
                matInput
                enforceAutocompleteSelection
                placeholder="Packaging Type "
                [formControlName]="SalvageFormNames.PackagingType"
                [matAutocomplete]="pkgCd"
                (keydown)="onPackageCdKeydown($event)"
              />
              <mat-autocomplete #pkgCd="matAutocomplete" [autoActiveFirstOption]="true">
                <mat-option *ngFor="let option of filteredOptionsPackageCd | async" [value]="option"> {{ option }} </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="getControl(SalvageFormNames.PackagingType).touched && getControl(SalvageFormNames.PackagingType).invalid">
                <div *ngIf="getControl(SalvageFormNames.PackagingType).errors.required">Packaging Type is required</div>
              </mat-error>
            </mat-form-field>

            <div class="salvageRequest__form-duoPanel-specialHandling">
              <div class="salvageRequest__form-duoPanel-specialHandling-heading">
                <label>Special Handling<span class="mandatory-field">*</span></label>
                <mat-icon class="salvageRequest__form-duoPanel-specialHandling-info" matTooltip="{{ specialHandlingTooltip }}" [matTooltipPosition]="spHandlingTooltipPosition">info</mat-icon>
              </div>
              <mat-radio-group class="salvageRequest__form-duoPanel-specialHandling-radioButtons" [formControlName]="SalvageFormNames.SpecialHandling">
                <mat-radio-button [value]="true">Yes</mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="getControl(SalvageFormNames.SpecialHandling).touched && getControl(SalvageFormNames.SpecialHandling).invalid">
                <div *ngIf="getControl(SalvageFormNames.SpecialHandling).errors.required" class="salvageRequest__form-duoPanel-specialHandling-error">Special Handling is required</div>
              </mat-error>
            </div>
          </div>

          <mat-form-field class="salvageRequest__form-formField salvageRequest__form-totalWeight" appearance="outline">
            <mat-label>Total Weight<span class="mandatory-field">*</span></mat-label>
            <input matInput [formControlName]="SalvageFormNames.TotalWeight" type="number" min="1" oninput="validity.valid||(value='');" placeholder="lbs" autocomplete="off" />
            <mat-error *ngIf="getControl(SalvageFormNames.TotalWeight).touched && getControl(SalvageFormNames.TotalWeight).invalid">
              <div *ngIf="getControl(SalvageFormNames.TotalWeight).errors.maxlength">Cannot accept more than 11 digits</div>
              <div *ngIf="getControl(SalvageFormNames.TotalWeight).errors.required">Weight is required</div>
            </mat-error>
          </mat-form-field>

          <h6>Dimensions</h6>
          <div class="salvageRequest__form-dimension">
            <mat-form-field class="salvageRequest__form-formField" appearance="outline">
              <mat-label>Length </mat-label>
              <input matInput [formControlName]="SalvageFormNames.LengthNbr" placeholder="Length" autocomplete="off" />
              <mat-error *ngIf="getControl(SalvageFormNames.LengthNbr).touched && getControl(SalvageFormNames.LengthNbr).invalid">
                <div *ngIf="getControl(SalvageFormNames.LengthNbr).errors.maxlength">Limit 7 digits</div>
                <div *ngIf="getControl(SalvageFormNames.LengthNbr).errors.pattern">Only numbers allowed</div>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="salvageRequest__form-formField" appearance="outline">
              <mat-label>Width </mat-label>
              <input matInput [formControlName]="SalvageFormNames.WidthNbr" placeholder="Width" autocomplete="off" />
              <mat-error *ngIf="getControl(SalvageFormNames.WidthNbr).touched && getControl(SalvageFormNames.WidthNbr).invalid">
                <div *ngIf="getControl(SalvageFormNames.WidthNbr).errors.maxlength">Limit 7 digits</div>
                <div *ngIf="getControl(SalvageFormNames.WidthNbr).errors.pattern">Only numbers allowed</div>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="salvageRequest__form-formField" appearance="outline">
              <mat-label>Height </mat-label>
              <input matInput [formControlName]="SalvageFormNames.HeightNbr" placeholder="Height" autocomplete="off" />
              <mat-error *ngIf="getControl(SalvageFormNames.HeightNbr).touched && getControl(SalvageFormNames.HeightNbr).invalid">
                <div *ngIf="getControl(SalvageFormNames.HeightNbr).errors.maxlength">Limit 7 digits</div>
                <div *ngIf="getControl(SalvageFormNames.HeightNbr).errors.pattern">Only numbers allowed</div>
              </mat-error>
            </mat-form-field>
          </div>
          <h6>or</h6>
          <mat-form-field class="salvageRequest__form-formField" appearance="outline">
            <mat-label>Cubic ft </mat-label>
            <input matInput [formControlName]="SalvageFormNames.CubeNbr" placeholder="Cubic ft." autocomplete="off" />
            <mat-error *ngIf="getControl(SalvageFormNames.CubeNbr).touched && getControl(SalvageFormNames.CubeNbr).invalid">
              <div *ngIf="getControl(SalvageFormNames.CubeNbr).errors.maxlength">Limit 7 digits</div>
              <div *ngIf="getControl(SalvageFormNames.CubeNbr).errors.pattern">Only numbers allowed</div>
            </mat-error>
          </mat-form-field>

          <div>
            <mat-form-field appearance="outline" class="salvageRequest__form-formField salvageRequest__form-quality">
              <mat-label>Quality of Freight<span class="mandatory-field">*</span></mat-label>
              <mat-select [formControlName]="SalvageFormNames.QualityofFreight">
                <mat-option *ngFor="let quality of qualityTypes" [value]="quality">
                  {{ QualityCdEnum[quality] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- <mat-autocomplete #qualityofFreightAutocomplete="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let quality of filteredQualityofFreightCd | async" [value]="quality">{{ QualityCdEnum[quality] }}</mat-option>
            </mat-autocomplete> -->
          </div>

          <mat-form-field appearance="outline" class="salvageRequest__form-formField salvageRequest__form-value">
            <mat-label>Value of Freight<span class="mandatory-field">*</span></mat-label>
            <mat-select [formControlName]="SalvageFormNames.ValueofFreight">
              <mat-option *ngFor="let valueofFreight of valueTypes" [value]="valueofFreight">
                {{ valueofFreight }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="getControl(SalvageFormNames.ValueofFreight).touched && getControl(SalvageFormNames.ValueofFreight).invalid">
              <div *ngIf="getControl(SalvageFormNames.ValueofFreight).errors.required">Value is required</div>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="salvageRequest__form-formField salvageRequest__form-comments">
            <mat-label>Comments</mat-label>
            <textarea matInput [formControlName]="SalvageFormNames.Comments" placeholder="Enter more details"></textarea>
          </mat-form-field>
          <mat-error class="salvageRequest__form-comments-error" *ngIf="getControl(SalvageFormNames.Comments).touched && getControl(SalvageFormNames.Comments).invalid">
            <div *ngIf="getControl(SalvageFormNames.Comments).errors.maxlength">Limit 4000 digits</div>
          </mat-error>
        </div>

        <div class="salvageRequest__form-proList">
          <div>Selected Items</div>
          <div *ngFor="let proNbr of selectedPROList" class="salvageRequest__form-proList-delete">
            <span class="salvageRequest__form-proList-delete-proNbr">{{ proNbr }}</span>
            <button [disabled]="selectedPROList.length === 1" class="salvageRequest__form-proList-delete-icon" (click)="deletePRO(proNbr)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="salvageRequest__form-questionnaire">
        <p class="salvageRequest__form-questionnaire-heading">OS&D Action Items (completion required PRIOR TO any salvage inquiry)</p>

        <div class="salvageRequest__form-question">
          <div class="salvageRequest__form-question-text">
            <label>
              <p class="salvageRequest__form-question-text-info">
                1. Are all related photos, communiques, notifications, inspections, and other required documents visible in Image?<span class="mandatory-field">*</span>
              </p>
              <p class="salvageRequest__form-question-text-extraInfo">
                <b>Important</b> - OVERAGE photos must be loaded only into the Photo Facility OVER/SHORT bucket in order to be visible to search engine
              </p>
            </label>
          </div>
          <div class="salvageRequest__form-question-options">
            <mat-radio-group [formControlName]="SalvageFormNames.VisibleInd">
              <mat-radio-button [value]="true" class="salvageRequest__form-question-options-yes">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <mat-error *ngIf="getControl(SalvageFormNames.VisibleInd).touched && getControl(SalvageFormNames.VisibleInd).invalid">
          <div *ngIf="getControl(SalvageFormNames.VisibleInd).errors.required" class="salvageRequest__form-question-error">The above field is required</div>
        </mat-error>
        <div class="salvageRequest__form-question">
          <div class="salvageRequest__form-question-text">
            <label>2. OVERAGES- Did you perform searches via APB’s, GOWB, Overage Photo App and Salvage Tool resources in an effort to match?<span class="mandatory-field">*</span></label>
          </div>
          <div class="salvageRequest__form-question-options">
            <mat-radio-group [formControlName]="SalvageFormNames.SearchInd">
              <mat-radio-button [value]="true" class="salvageRequest__form-question-options-yes">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <mat-error *ngIf="getControl(SalvageFormNames.SearchInd).touched && getControl(SalvageFormNames.SearchInd).invalid">
          <div *ngIf="getControl(SalvageFormNames.SearchInd).errors.required" class="salvageRequest__form-question-error">The above field is required</div>
        </mat-error>
      </div>
    </div>
    <div class="salvageRequest__form-bottomPanel">
      <button (click)="salvageFormSubmit()" mat-flat-button xpoLargeButton xpoButton color="primary" class="salvageRequest__form-submit">
        Submit
      </button>

      <button (click)="salvageFormCancel()" mat-stroked-button xpoLargeButton xpoButton color="primary" class="salvageRequest__form-cancel" type="reset">
        Cancel
      </button>
    </div>
  </form>
</div>
