import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { detect } from 'detect-browser';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppFooterComponent implements OnInit {

  public browserInfo;
  constructor(private configManagerService: ConfigManagerService) {}

  ngOnInit() {
    this.browserInfo = detect();
  }

  public get buildVersion(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  }
}
