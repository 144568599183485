import { XpoLtlValidationRegexPatterns } from '@xpo-ltl/ngx-ltl';
import { trim as _trim, toString as _toString } from 'lodash';
/**
 * validate the user entered pro is a valid child pro or not
 * @param value
 * @returns
 */
export function isValidChildPro(value: string): boolean {
  let val = value.replace('-', '');
  if (val.length == 11) {
    val = val.substring(1);
  }
  const checkDigit = Number(val.substring(9));
  const number = Number(val.substring(0, 9));
  const modNbr = number % 7;
  return modNbr === checkDigit && val.length === 10;
}

export function isValidParentProNumber(value: string) {
  if (!new RegExp(XpoLtlValidationRegexPatterns.validProCharacterCheck).test(_toString(value))) {
    return false;
  }
  let val = value.replace('-', '');
  if (val === '' || val.length < 9 || val.length > 11) {
    return false;
  }
  // divide first 8 chars by 7 multiple remainder by 7
  const checkDigit = Number(val.substring(8));
  const number = Number.parseInt(val.substring(0, 8));
  const mod = number % 7;
  return mod === checkDigit;
}

/**
 * Formats the given pro into hypenated 10 digit format(xxxx-pppppp)
 * @param value
 * @returns
 */
export function formatTenDigitProNbr(value: string): string {
  let val = value.replace('-', '');
  if (val.length == 11) {
    val = val.substring(1);
  }
  return `${val.slice(0, 4)}-${val.slice(4)}`;
}

/**
 * Replace non-dig characters in the entered string
 */
export function replaceNonDigitChars(input: string): string {
  return input.replace(/\D/g, '');
}

export function trimChars(string = '', what = ',', flags = 'g') {
  const escaped = what.replace(/[\[\](){}?*+\^$\\.|\-]/g, '\\$&');
  const find = new RegExp('^[' + escaped + ']', flags);

  return string.replace(find, '');
}
