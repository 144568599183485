export enum SalvageRequestStatusResponseTemplate {
  'Approved for CMK (US)' = 'ApprovedForCMK',
  'Approved for CSV (Canada)' = 'ApprovedForCSV',
  'Closed - Return to Customer' = 'ClosedReturnToCustomer',
  'Closed - Moved to CMK Without Salvage Approval' = 'ClosedNotApproved',
  'Closed' = 'Closed',
  'Approved for Common Disposal' = 'ApprovedForCommonDisposal',
  'Approved for Regulated Disposal' = 'ApprovedForRegulatedDisposal',
  'Approved for Company Use' = 'ApprovedForCompanyUse',
  'Inspection Report Needed' = 'InspectionReportNeeded',
  'Request for Photos' = 'RequestForPhotos',
  'Request for Feedback' = 'RequestForFeedback',
  'Feedback Submitted' = 'FeedbackSubmitted',
  'Open' = 'Open',
}
