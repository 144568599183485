export enum ConfigManagerProperties {
  production = 'production',
  scopeOptions = 'scopeOptions',
  apiUrl = 'apiUrl',
  secretToken = 'secretToken',
  region = 'region',
  appName = 'appName',
  buildVersion = 'buildVersion',
  loggedInUserRoot = 'loggedInUserRoot',
  customerApiEndpoint = 'customerApiEndpoint',
  invoiceApiEndpoint = 'invoiceApiEndpoint',
  userApiEndpoint = 'userApiEndpoint',
  dmsApiEndpoint = 'dmsApiEndpoint',
  userContextPath = 'userContextPath',
  errorToastDuration = 'errorToastDuration',
  successToastDuration = 'successToastDuration',
  feedbackToAddress = 'feedbackToAddress',
  feedbackSubject = 'feedbackSubject',
  imageCorpCode = 'imageCorpCode',
  imageDocClass = 'imageDocClass',
  maxReleaseRows = 'maxReleaseRows',
  maxResendRows = 'maxResendRows',
  cutoffDate = 'cutoffDate',
  supportAddress = 'supportAddress',
}
