import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { map, catchError } from 'rxjs/operators';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';
import { AppConstantsService } from '../services/app-constants.service';
import { User } from '@xpo-ltl/sdk-common';
import { Observable, of } from 'rxjs';
import { XpoDialogManagerService } from '../services/xpo-dialog-manager.service';
import { NotAuthorizedComponent } from '../components/not-authorized/not-authorized.component';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';

@Injectable()
export class RoleCheckGuard implements CanActivate {
  constructor(
    private loginService: XpoLtlLoggedInUserService,
    private config: ConfigManagerService,
    private router: Router,
    private appConstantsService: AppConstantsService,
    private dialogManager: XpoDialogManagerService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.loginService.getLoggedInUser(this.config.getSetting(ConfigManagerProperties.loggedInUserRoot)).pipe(
      map((user: User) => {
        if (this.appConstantsService.isAthorizedUser(user)) {
          this.appConstantsService.user = user;
          return true;
        }
        this.appConstantsService.user = undefined;
        // this.showNotAuthorizedDialog();
        return true;
      }),
      catchError(error => {
        this.appConstantsService.user = undefined;
        //   this.showNotAuthorizedDialog();
        return of(true);
      })
    );
  }

  private showNotAuthorizedDialog() {
    this.dialogManager.alert(
      {
        titleText: 'No Authorization',
        contentComponent: NotAuthorizedComponent,
        injectedData: of({ isSuccess: false }),
        hideDismissButton: true,
      },
      {
        disableClose: true,
        width: '600',
      }
    );
  }
}
