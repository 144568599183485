import { Pipe, PipeTransform } from '@angular/core';
import { ConditioningService } from '@xpo-ltl/common-services';
import * as _ from 'lodash';

@Pipe({
  name: 'proNumberFormatter',
})
export class ProNumberFormatterPipe implements PipeTransform {
  constructor(private conditioningService: ConditioningService) {}

  transform(value: any, args?: any): any {
    let formatDigits = 10;
    if (args) {
      formatDigits = Number.parseInt(args);
    }


    const a = this.conditionProNumber(value, formatDigits);
    return a;
  }

  public conditionProNumber(proNumber = '', digits = 9) {
    if ( proNumber === '' || proNumber === null ) {
      return '';
    }

    if(this.isValidChildPro(proNumber)) {
      return this.handle10DigitProNum(proNumber);
    }

    proNumber = this.clean(proNumber);
    proNumber = this.convert(proNumber, digits);
    return proNumber;
  }

  private clean(proNumber) {
    return proNumber.replace(/\D/g, '');
  }

  private convert(proNumber = '', digits = 9) {
    let part1, part2, trim, trimmed;
    trim = _.partialRight(this.trim.bind(this), '0', 'g', digits);
    trimmed = trim(proNumber);

    if (trimmed.length < 9) { // if it had a leading 0 as part of the real number
      if (trimmed.length < 8) {
        trimmed = `00${trimmed}`;
      } else {
        trimmed = `0${trimmed}`;
      }
    }
    part1 = trimmed.slice(0, 3);
    part2 = trimmed.slice(3);
    part2 = (part2.length > 6) ? trim(part2) : part2;

    let retValue = '';
    if ( digits === 11 ) {
      retValue = `0${part1}0${part2}`;
    } else if ( digits === 10 ) {
      retValue = `${part1}-${part2}`;
    } else {
      retValue = part1.concat(part2);
    }

    return retValue;
  }

  private trim(string = '', what = ',', flags = 'g') {
    let escaped, find;

    escaped = what.replace(/[\[\](){}?*+\^$\\.|\-]/g, '\\$&');
    find = new RegExp('^[' + escaped + ']', flags);

    return string.replace(find, '');
  }

  handle10DigitProNum(value: any) {
    var val = value.replace('-', '')
    if(val.length == 11) {
      val = val.substring(1);
    }
    return val.slice(0, 4) + '-' + val.slice(4)
  }

  isValidChildPro(value : any) : boolean {
    var val = value.replace('-', '')
    if(val.length == 11) {
      val = val.substring(1);
    }
    const checkDigit = Number(val.substring(9));
    const number = Number(val.substring(0, 9));
    const mod = (number % 7);
    if(mod === checkDigit && val.length == 10) {
      return true;
    }
    return false;
  }
}
