<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.3/css/all.css" integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/" crossorigin="anonymous" />
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

<div class="detailsPage">
  <input #imagesInput hidden label="Image" type="file" accept="image/*" (change)="processImage($event)" multiple="true" ngModel (click)="imagesInput.value = null" />
  <mat-card class="detailsCard">
    <div class="imageContainer">
      <div>
        <div *ngIf="!updateIndicator" class="imageCount">Photos Uploaded ({{ images.length }})</div>
        <div *ngIf="updateIndicator" class="imageCount">Photos Uploaded ({{ galleryImages2.length }})</div>

        <button *ngIf="!updateIndicator" mat-button color="primary" id="uploadImages" (click)="imagesInput.click()">Upload More</button>
        <button *ngIf="!updateIndicator" mat-button class="delete" [disabled]="this.selectedImages.length == 0" (click)="deleteImages()"><mat-icon>delete</mat-icon></button>
      </div>
      <mat-divider class="divider-line"></mat-divider>
      <div class="spaced"></div>
      <div *ngIf="loading_complete && !updateIndicator" class="image-enlarge-text">Click the image to enlarge</div>
      <div *ngIf="loading_complete && !updateIndicator"><ngx-gallery [options]="galleryOptions" [images]="galleryImages"> </ngx-gallery></div>

      <div *ngIf="loadingDMSImages && updateIndicator" class="spinner">
        <!--  <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple">
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner> -->
        <mat-spinner><div>Loading DMS Images</div></mat-spinner>
      </div>
      <div *ngIf="updateIndicator && !loadingDMSImages"><ngx-gallery class="gallery" [options]="galleryOptions2" [images]="galleryImages2"> </ngx-gallery></div>

      <button *ngIf="!updateIndicator && loading_complete" mat-stroked-button color="primary" [disabled]="imageAnalysisInProgress" (click)="analyzeImages()" class="analyzeImages">
        Analyze Images
      </button>
      <div *ngIf="!loading_complete && !updateIndicator" class="progress-indicator">
        <div>Optimizing the Images</div>
        <mat-progress-bar class="analyzeImages" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
    <mat-divider class="seperator" [vertical]="true"></mat-divider>
    <div class="rest">
      <div>
        <div class="form-title-hdr">
          Capture Photo Details <button class="closeButton" *ngIf="updateIndicator" [mat-dialog-close]="false"><mat-icon>clear</mat-icon></button>
        </div>

        <div class="help-desc">Please give as much detail about the product as possible to ensure accuracy. These details will apply to all photos uploaded.</div>
      </div>

      <div class="imageDetails">
        <form [formGroup]="imageUploadFormGroup">
          <div class="error-container" *ngIf="inValid$ | async"><div class="error-message">Please correct the below errors before saving.</div></div>
          <mat-form-field class="label-field">
            <mat-label>Selected Tags</mat-label>
            <input #tags matInput placeholder="Selected Tags" [formControlName]="ImageUploadFormNames.SelectedTags" />
            <mat-hint align="end">{{ tags.value.length }} / 4000</mat-hint>
            <mat-error *ngIf="hasError('maxlength', imageUploadFormGroup, ImageUploadFormNames.SelectedTags)">
              Selected Tags cannot be more than 4000 characters {{ tags.value.length }} / 4000</mat-error
            >
          </mat-form-field>
          <mat-form-field class="label-field">
            <mat-label>Product Description<span class="mandatory-field">*</span></mat-label>
            <textarea #desc matInput placeholder="Product Description" [formControlName]="ImageUploadFormNames.Description" [matAutocomplete]="descAuto"></textarea>
            <mat-autocomplete #descAuto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptionsDesc | async" [value]="desc.value?.substring(0, desc.value?.lastIndexOf(',') + 1) + option"> {{ option }} </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.Description)"> Description is Required </mat-error>
            <mat-error *ngIf="hasError('maxlength', imageUploadFormGroup, ImageUploadFormNames.Description)"> Description cannot be more than 4000 characters {{ desc.value.length }} / 4000</mat-error>
            <mat-hint align="end">{{ desc.value.length }} / 4000</mat-hint>
          </mat-form-field>
          <div class="fieldRow">
            <mat-form-field class="custom-form-group-info">
              <mat-label>PRO #<span class="mandatory-field">*</span></mat-label>
              <input matInput placeholder="PRO #" [formControlName]="ImageUploadFormNames.ProNbr" />
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.ProNbr)"> PRO Number is Required. </mat-error>
              <mat-error *ngIf="hasError('invalidProFormat', imageUploadFormGroup, ImageUploadFormNames.ProNbr)"> PRO Number is invalid. </mat-error>
            </mat-form-field>
            <mat-form-field class="custom-form-group-info">
              <mat-label>SIC<span class="mandatory-field">*</span></mat-label>
              <input matInput placeholder="SIC " [formControlName]="ImageUploadFormNames.ReportingSicCd" oninput="this.value = this.value.toUpperCase()" />
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.ReportingSicCd)"> SIC Code is Required </mat-error>
              <mat-error *ngIf="hasError('pattern', imageUploadFormGroup, ImageUploadFormNames.ReportingSicCd)"> SIC Code format is Invalid </mat-error>
              <mat-error *ngIf="hasError('invalidSicCode', imageUploadFormGroup, ImageUploadFormNames.ReportingSicCd)"> SIC Code is not Usable </mat-error>
            </mat-form-field>
            <mat-form-field class="custom-form-group-info">
              <mat-label>Packaging Type<span class="mandatory-field">*</span></mat-label>
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.PackageCd)"> Packaging Code is Required. </mat-error>
              <mat-error *ngIf="hasError('invalidOption', imageUploadFormGroup, ImageUploadFormNames.PackageCd)"> Packaging Code is Invalid </mat-error>
              <input matInput placeholder="Packaging Type " [formControlName]="ImageUploadFormNames.PackageCd" [matAutocomplete]="pkgCd" (keydown)="onPackageCdKeydown($event)" />
              <mat-autocomplete #pkgCd="matAutocomplete" [autoActiveFirstOption]="true">
                <mat-option *ngFor="let option of filteredOptionsPackageCd | async" [value]="option"> {{ option }} </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="custom-form-group-info">
              <mat-label>Quantity<span class="mandatory-field">*</span></mat-label>
              <input matInput placeholder="Quantity" [formControlName]="ImageUploadFormNames.PiecesCount" />
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.PiecesCount)"> Quantity is Required. </mat-error>
              <mat-error *ngIf="hasError('min', imageUploadFormGroup, ImageUploadFormNames.PiecesCount)"> Minimum required quantity is 1. </mat-error>
              <mat-error *ngIf="hasError('pattern', imageUploadFormGroup, ImageUploadFormNames.PiecesCount)"> Quantity needs to be a number. </mat-error>
              <mat-error *ngIf="hasError('maxlength', imageUploadFormGroup, ImageUploadFormNames.PiecesCount)"> Quantity can’t be more than 7 digits. </mat-error>
            </mat-form-field>
            <mat-form-field class="custom-form-group-info">
              <mat-label>Brand/Manufacturer<span class="mandatory-field">*</span></mat-label>
              <input matInput #brand placeholder="Brand/Manufacturer" [formControlName]="ImageUploadFormNames.Brand" [matAutocomplete]="auto" />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="brand.value?.substring(0, brand.value?.lastIndexOf(',') + 1) + option"> {{ option }} </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.Brand)"> Brand is Required. </mat-error>
            </mat-form-field>
            <mat-form-field class="custom-form-group-info-color">
              <mat-chip-list #chipList [formControlName]="ImageUploadFormNames.Color">
                <mat-chip *ngFor="let color of colors" class="info-color-chip" [selectable]="true" [removable]="true" (removed)="removeColor(color)">
                  {{ color }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-label>Color<span class="mandatory-field">*</span></mat-label>
                <input
                  #colorInput
                  [formControl]="colorSearchBox"
                  [matAutocomplete]="autoColor"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="addColor($event)"
                  (keydown)="onColorSearchBoxKeydown($event)"
                />
              </mat-chip-list>
              <mat-autocomplete #autoColor="matAutocomplete" (optionSelected)="selectedColor($event)">
                <mat-option *ngFor="let color of filteredColors | async" [value]="color"> {{ color }} <span [ngClass]="colorClass(color)"></span> </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.Color)"> Color is Required. </mat-error>
            </mat-form-field>
          </div>
          <div class="fieldRow">
            <mat-form-field class="custom-form-group-info-refNbrs">
              <mat-label>Reference #'s (Model #, Serial #, UPC #)</mat-label>
              <input #refNbrs matInput placeholder="Reference #'s (Model #, Serial #, UPC #)" [formControlName]="ImageUploadFormNames.ReferenceNbr" [matAutocomplete]="refNbr" />
              <mat-autocomplete #refNbr="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptionsSerialNbr | async" [value]="refNbrs.value?.substring(0, refNbrs.value?.lastIndexOf(',') + 1) + option"> {{ option }} </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="fieldRow">
            <mat-form-field class="custom-form-group-info">
              <mat-label>Length</mat-label>
              <input matInput appDecimalNumber placeholder="Length" type="number" min="0" [formControlName]="ImageUploadFormNames.LengthNbr" />
              <mat-hint>Inches</mat-hint>
              <mat-error *ngIf="hasError('maxlength', imageUploadFormGroup, ImageUploadFormNames.LengthNbr)"> Length should be between 0.0 to 999.99 </mat-error>
            </mat-form-field>
            <mat-form-field class="custom-form-group-info">
              <mat-label>Width/Diam</mat-label>
              <input matInput appDecimalNumber placeholder="Width/Diam" type="number" min="0" [formControlName]="ImageUploadFormNames.WidthNbr" />
              <mat-hint>Inches</mat-hint>
              <mat-error *ngIf="hasError('maxlength', imageUploadFormGroup, ImageUploadFormNames.WidthNbr)"> Width should be between 0.0 to 999.99 </mat-error>
            </mat-form-field>
            <mat-form-field class="custom-form-group-info">
              <mat-label>Height</mat-label>
              <input matInput appDecimalNumber placeholder="Height" type="number" min="0" [formControlName]="ImageUploadFormNames.HeightNbr" />
              <mat-hint>Inches</mat-hint>
              <mat-error *ngIf="hasError('maxlength', imageUploadFormGroup, ImageUploadFormNames.HeightNbr)"> Height should be between 0.0 to 999.99 </mat-error>
            </mat-form-field>
            <mat-form-field class="custom-form-group-info">
              <mat-label>Weight</mat-label>
              <input matInput appDecimalNumber placeholder="Weight" type="number" min="0" [formControlName]="ImageUploadFormNames.WeightLbs" />
              <mat-hint>Lbs</mat-hint>
              <mat-error *ngIf="hasError('maxlength', imageUploadFormGroup, ImageUploadFormNames.WeightLbs)"> Weight should be between 0.0 to 99999999.99 </mat-error>
            </mat-form-field>
            <mat-form-field class="custom-form-group-info">
              <mat-label>Upload Date</mat-label>
              <input matInput placeholder="Reference # " [formControlName]="ImageUploadFormNames.UploadDate" />
            </mat-form-field>
            <!--  <mat-slide-toggle *ngIf="!updateIndicator" [color]="primary" (change)="toggleAutoComplete()" checked="true"> Autocomplete </mat-slide-toggle> -->
            <mat-form-field *ngIf="updateIndicator" class="custom-form-group-info">
              <mat-label>Original PRO #</mat-label>
              <input matInput placeholder="Original PRO #" [formControlName]="ImageUploadFormNames.OriginProNbr" />
              <mat-hint *ngIf="matchClicked">*Required</mat-hint>
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.OriginProNbr)">Original PRO # required </mat-error>
              <mat-error *ngIf="hasError('invalidProFormat', imageUploadFormGroup, ImageUploadFormNames.OriginProNbr)">Original PRO Number is invalid. </mat-error>
            </mat-form-field>
          </div>
          <div class="lastRow">
            <mat-form-field *ngIf="updateIndicator" appearance="fill" class="custom-form-group-comments">
              <mat-label>Previous Comments</mat-label>
              <textarea class="text" matInput placeholder="Previous Comments" [disabled]="true">{{ previousComments }}</textarea>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="matchClicked || unMatchClicked || partialMatchClicked" class="custom-form-group-comments">
              <mat-label>Comments</mat-label>
              <textarea class="text" matInput placeholder="Comments" [formControlName]="ImageUploadFormNames.Comment"></textarea>
              <mat-hint *ngIf="unMatchClicked || partialMatchClicked">*Required</mat-hint>
              <mat-error *ngIf="hasError('required', imageUploadFormGroup, ImageUploadFormNames.Comment)">Comments are required </mat-error>
            </mat-form-field>
          </div>
          <div class="dialog-action-buttons" *ngIf="updateIndicator">
            <button class="actionButton" mat-button color="primary" type="button" (click)="cancelDialog()">Cancel</button>
            <button
              class="actionButton"
              mat-raised-button
              color="primary"
              *ngIf="(imagestatus == ImageStatus.UnMatched && !matchClicked) || (imagestatus == ImageStatus.PartialMatched && !partialMatchClicked && !matchClicked)"
              (click)="matchClicked = true"
            >
              Match
            </button>
            <button class="actionButton" mat-raised-button color="primary" *ngIf="imagestatus == ImageStatus.Matched && !unMatchClicked && !partialMatchClicked" (click)="partialMatchClicked = true">
              Partial Match
            </button>
            <button class="actionButton" mat-raised-button color="primary" *ngIf="imagestatus == ImageStatus.Matched && !unMatchClicked && !partialMatchClicked" (click)="unMatchClicked = true">
              UnMatch
            </button>
            <button class="actionButton" mat-raised-button color="primary" *ngIf="matchClicked" (click)="matchImage()">Confirm Match</button>
            <button class="actionButton" mat-raised-button color="primary" *ngIf="unMatchClicked" (click)="unMatchImage()">Confirm UnMatch</button>
            <button class="actionButton" mat-raised-button color="primary" *ngIf="partialMatchClicked" (click)="partialMatchImage()">Confirm Partial Match</button>
          </div>
          <div class="action-buttonss" *ngIf="!updateIndicator">
            <button mat-button color="primary" type="button" (click)="cancel()">Cancel</button> <button mat-raised-button color="primary" (click)="saveImage()">Save Photos</button>
          </div>
        </form>
      </div>
    </div>
  </mat-card>
</div>
